import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface AlertModel {
  title?: string;
  message?: string;
  cancelButton?: boolean;
}

@Component({
  selector: 'app-alert',
  template: `
    <div class="modal-content-custom">
      <div class="modal-header-custom">
        <h3>{{ title }}</h3>
      </div>
      <div class="modal-body-custom">
        <p>{{ message }}</p>
      </div>
      <div class="modal-footer-custom">
        <button class="cancel" *ngIf="cancelButton" (click)="close()">{{ 'alert-close-button-text'|transloco }}</button>
        <button class="confirm" (click)="confirm()">{{ 'alert-accept-button-text' | transloco }}</button>
      </div>
    </div>
  `,
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent extends SimpleModalComponent<AlertModel, boolean> implements AlertModel {
  title: string | undefined;
  message: string | undefined;
  cancelButton: boolean | undefined;
  constructor() {
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }
}
