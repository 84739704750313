import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgModel } from '@angular/forms';
import { GetBrokerRequest, DispatchControl, MfvBehaviorControl } from '../state';
import { StateService } from '../state.service';
import { DOCUMENT } from '@angular/common';
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-broker-display',
  templateUrl: './broker-display.component.html',
  styleUrls: ['./broker-display.component.scss']
})
export class BrokerDisplayComponent implements OnInit {

  constructor(
    private stateService: StateService,
    @Inject(DOCUMENT) private document: Document,
    private translation: TranslocoService
  ) { }

  ngOnInit(): void {
    this.stateService.isBrokerFlowObs.subscribe(
      value => {
        console.warn('broker display component: broker flow is', value)
        this.isBrokerFlow = value
      }
    )

    this.stateService.dataSharingRequestedWithCalingoFlavor.subscribe(
      value => {
        this.dataSharingRequestedWithCalingoFlavor = value
      }
    )

    this.stateService.brokerDisplayNameObs.subscribe(
      value => {
        this.brokerName = value
      }
    )

    this.stateService.getBrokerObject().subscribe(
      value => {
        if (value.simpegoEmailAdresses) {
          if (value.email) {
            if (value.simpegoEmailAdresses.includes(value.email)) {
              this.brokerEmails = value.simpegoEmailAdresses
            } else {
              let mails = value.simpegoEmailAdresses
              mails.push(value.email)
              this.brokerEmails = mails
            }
          } else {
            this.brokerEmails = value.simpegoEmailAdresses
          }

        }
        /*
        if (value.email) {
          this.selectedBrokerEmail = value.email // TODO: Remove this, it is what causes the email to be preset, rather take it from state.
        }
        */
      }
    )

    this.stateService.getState().subscribe(value => {

      if (this.stateService.stateCorrespondsToBrokerMode(value)) {
        if (value.brokerEmail) {
          console.warn('setting broker email address in state subscription')
          this.selectedBrokerEmail = value.brokerEmail
          if (!value.mfvBehaviorControl?.dispatchControl) {
            this.saveDispatchControlLocally() // save the default, but only do it initially, i.e. when there is no dispatch control set, otherwise it causes an endless loop.
          } else {
            // if there is a dispatchControl object in the state, we take one of its values to set the selection
            this.selectedDispatchMode = value.mfvBehaviorControl.dispatchControl.contract
          }
          if (this.brokerEmails?.length == 1) {
            this.showBrokerUpdateInput = false
          }
        } else {
          console.warn('showing broker selection component in state subscription')
          this.showBrokerUpdateInput = true
        }
      }

    })

    this.stateService.getWhitelabellingUIController().subscribe(value => {
      if (value.showDispatchControl === true) {
        this.showBrokerUpdateInput = true
      }
    })

    this.stateService.getMissingBrokerEmailNotifier().subscribe(value => {
      console.warn('got message from missing broker email notifier, value is', value)
      if (value) {
        /*
        let divToFocus = this.document.getElementById('broker-component')
        if (divToFocus !== null && divToFocus !== undefined && (divToFocus as HTMLElement).focus !== undefined) {
          setTimeout(() => (divToFocus as HTMLElement).focus(), 10)
          console.warn('trying to focus div')
        } else {
          console.warn('cannot focus div')
        }
        */
        window.scrollTo(0, 0)
        this.submittedIncomplete = true
      }
    })
  }

  isBrokerFlow: boolean = false
  dataSharingRequestedWithCalingoFlavor: boolean = false
  brokerName: string | undefined
  showBrokerUpdateInput: boolean = true

  submittedIncomplete: boolean = false

  selectedBrokerEmail: string = '' // this.stateService.getBrokerObjectSnapshot()?.email || '' // TODO: Make sure that this is empty on load or if there is only one it is the one which is preselected
  brokerEmails: string[] | null = []

  dispatchOptions: Option[] = [
    {name: () => this.translation.translate('broker-display-customer'), id: 'customer'},
    {name: () => this.translation.translate('broker-display-agent'), id: 'agent'},
    {name: () => this.translation.translate('broker-display-customer-and-agent'), id: 'customer_and_agent'}
  ]

  selectedDispatchMode = 'customer_and_agent' // default

  toggleEditBroker() {
    this.showBrokerUpdateInput = !this.showBrokerUpdateInput
  }

  getBrokerData(email: string) {
    console.warn('broker email address', email)
    this.stateService.updateBrokerData({email: email})
    this.toggleEditBroker()
  }

  setBrokerEmailAndDispatchControlIfPresent(email: string): void {
    this.stateService.setBrokerEmail(email)
    if (this.showDispatchControl()) {

      let dc: DispatchControl = {
        contract: this.selectedDispatchMode,
        invoice: this.selectedDispatchMode,
        reminder: this.selectedDispatchMode,
        offerEmail: 'agent',
        orderEmail: 'agent',
        agentEmail: this.selectedBrokerEmail
      }

      let state = this.stateService.getSnapshot()
      if (state.mfvBehaviorControl) {
        state.mfvBehaviorControl.dispatchControl = dc
      } else {
        let mbc: MfvBehaviorControl = {dispatchControl: dc}
        state.mfvBehaviorControl = mbc
      }
      this.stateService.setState(state)
      // this.stateService.setLocaState(state)
    }
    // this.toggleEditBroker()
  }

  saveDispatchControlLocally() {
    if (this.showDispatchControl()) {

      let dc: DispatchControl = {
        contract: this.selectedDispatchMode,
        invoice: this.selectedDispatchMode,
        reminder: this.selectedDispatchMode,
        offerEmail: 'agent',
        orderEmail: 'agent',
        agentEmail: this.selectedBrokerEmail
      }

      let state = this.stateService.getSnapshot()
      if (state.mfvBehaviorControl) {
        state.mfvBehaviorControl.dispatchControl = dc
      } else {
        let mbc: MfvBehaviorControl = {dispatchControl: dc}
        state.mfvBehaviorControl = mbc
      }
      // this.stateService.setState(state)
      this.stateService.setLocalState(state)

    }
  }

  saveBrokerEmailAdressLocally() {
    let state = this.stateService.getSnapshot()
    state.brokerEmail = this.selectedBrokerEmail
    this.stateService.setLocalState(state)
    this.submittedIncomplete = false
  }

  brokerEmailChangeListener(email: string): void {
    this.selectedBrokerEmail = email
    console.warn('broker email changed:', this.selectedBrokerEmail)
    this.saveBrokerEmailAdressLocally()
  }

  dispatchControlChangeListener(dc: string): void {
    this.selectedDispatchMode = dc
    console.warn('dispatch control changed:', this.selectedDispatchMode)
    this.saveDispatchControlLocally()
  }

  showDispatchControl(): boolean {
    return this.stateService.getWhitelabellingUIControllerSnapshot().showDispatchControl === true
  }

  emailFilterFunction(term: string, item: string): boolean {
    let termLc = term.toLowerCase();
    return item.toLowerCase().indexOf(termLc) > -1
  }

}

interface Option {
  name: () => string,
  id: string
}
