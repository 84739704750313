<div class="flex-wrapper">

  <h2 class="cta" *ngIf="getPetName()" [innerHtml]="'banner-header-2' | transloco : {petName  : getPetName() }"></h2>
  <h2 class="cta" *ngIf="!getPetName()" [innerHtml]="'banner-header-fallback' | transloco"></h2>

    <div class="sub-page-root-coverage">

        <form id="coverage-info-form" [formGroup]="coverageInfoForm">

            <div class="bg-container">
              <div class="table-wrapper" [class.no-br-top-left]="isPackageRecommended('s')" [class.no-br-top-right]="isPackageRecommended('l')">
                  <div class="table-header" #tableHeader>
                      <div class="table-header-element" [class.recommended]="isPackageRecommended('s')">
                          <div class="recommended-text">
                            {{'coverage-info-recommended' | transloco}}
                          </div>
                          <div class="table-header-element-div">
                            <h3 [innerHtml]="'coverage-info-basic-label' | transloco" class="table-header-title"></h3>
                            <h4 [innerHtml]="'coverage-info-basic-price' | transloco : {displayPremiumMonthlygetSTotalnumber : displayPremiumMonthly(getSBase()) | number:'1.2-2' }" class="table-header-subtitle"></h4>
                            <p [innerHtml]="'coverage-info-basic-explanation' | transloco" class="table-header-text"></p>
                            <label for="s1">
                                <input id="s1" class="form-control" value="s" type="radio"
                                    (click)="track('coverage-level', 'selector', 's', 'top')"
                                    formControlName="coverageLevel">
                                <div [class.submitted-incomplete]="submittedIncomplete" class="choice-div-short-coverage "
                                     [class.sibling-selected]="isSelectedById('m1') || isSelectedById('l1') || isSelectedById('m2') || isSelectedById('l2')"
                                     [class.selected]="isSelectedCoverageLevel('s')">
                                  <p *ngIf="!isSelectedCoverageLevel('s')" [innerHtml]="'coverage-info-basic-select' | transloco" class="center-center-p bold"></p>
                                  <p *ngIf="isSelectedCoverageLevel('s')" [innerHtml]="'coverage-info-basic-select-selected' | transloco" class="center-center-p bold"></p>
                                </div>
                            </label>
                          </div>
                      </div>
                      <div class="table-header-element" [class.recommended]="isPackageRecommended('m')">
                          <div class="recommended-text">
                            {{'coverage-info-recommended' | transloco}}
                          </div>
                          <div class="table-header-element-div">
                            <h3 [innerHtml]="'coverage-info-regular-label' | transloco" class="table-header-title"></h3>
                            <h4 [innerHtml]="'coverage-info-regular-price' | transloco : {displayPremiumMonthlygetMTotalnumber : displayPremiumMonthly(getMBase()) | number:'1.2-2' }" class="table-header-subtitle"></h4>
                            <p [innerHtml]="'coverage-info-regular-explanation' | transloco" class="table-header-text"></p>
                            <label for="m1">
                                <input id="m1" class="form-control" value="m" type="radio"
                                    (click)="track('coverage-level', 'selector', 'm', 'top')"
                                    formControlName="coverageLevel">
                                <div [class.submitted-incomplete]="submittedIncomplete" class="choice-div-short-coverage"
                                     [class.sibling-selected]="isSelectedById('s1') || isSelectedById('l1') || isSelectedById('s2') || isSelectedById('l2')"
                                     [class.selected]="isSelectedCoverageLevel('m')">
                                  <p *ngIf="!isSelectedCoverageLevel('m')" [innerHtml]="'coverage-info-regular-select' | transloco" class="center-center-p bold"></p>
                                  <p *ngIf="isSelectedCoverageLevel('m')" [innerHtml]="'coverage-info-regular-select-selected' | transloco" class="center-center-p bold"></p>
                                </div>
                            </label>
                          </div>
                      </div>
                      <div class="table-header-element" [class.recommended]="isPackageRecommended('l')">
                          <div class="recommended-text">
                            {{'coverage-info-recommended' | transloco}}
                          </div>
                          <div class="table-header-element-div">
                            <h3 [innerHtml]="'coverage-info-complete-label' | transloco" class="table-header-title"></h3>
                            <h4 [innerHtml]="'coverage-info-complete-price' | transloco : {displayPremiumMonthlygetLTotalnumber : displayPremiumMonthly(getLBase()) | number:'1.2-2' }" class="table-header-subtitle"></h4>
                            <p [innerHtml]="'coverage-info-complete-explanation' | transloco" class="table-header-text"></p>
                            <label for="l1">
                                <input id="l1" class="form-control" value="l" type="radio"
                                    (click)="track('coverage-level', 'selector', 'l', 'top')"
                                    formControlName="coverageLevel">
                                <div [class.submitted-incomplete]="submittedIncomplete" class="choice-div-short-coverage"
                                     [class.sibling-selected]="isSelectedById('s1') || isSelectedById('m1') || isSelectedById('s2') || isSelectedById('m2')"
                                     [class.selected]="isSelectedCoverageLevel('l')">
                                  <p *ngIf="!isSelectedCoverageLevel('l')" [innerHtml]="'coverage-info-complete-select' | transloco" class="center-center-p bold"></p>
                                  <p *ngIf="isSelectedCoverageLevel('l')" [innerHtml]="'coverage-info-complete-select-selected' | transloco" class="center-center-p bold"></p>
                                </div>
                            </label>
                          </div>
                      </div>
                  </div>

                  <div class="table-header-placeholder" #tableHeaderPlaceholder>
                  </div>

                  <div class="table-pack">
                      <div class="table-label-div-row">
                          <p [innerHtml]="'coverage-info-annual-vet-fee-label' | transloco" class="table-label-text bold"></p>
                          <span (click)="showAlert(this.annualVetFeeLimitInfoTitle, this.annualVetFeeLimitInfoMessage)"
                              class="infobutton">&#9432;</span>
                      </div>
                      <div class="table-value-div-row">
                        <div class="table-value-col">
                              <p class="table-value-text">CHF 3'000</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('m')">
                              <p class="table-value-text">CHF 6'000</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('l')">
                              <p class="table-value-text">CHF 12'000</p>
                          </div>
                      </div>
                  </div>

                  <div class="table-pack">
                      <div class="table-label-div-row">
                          <p [innerHtml]="'coverage-info-treatment-cover-accidents-and-illness-label' | transloco" class="table-label-text bold"></p>
                          <span (click)="showAlert(this.treatmentAccidentsAndIllnessInfoTitle, this.treatmentAccidentsAndIllnessInfoMessage)"
                              class="infobutton">&#9432;</span>

                      </div>
                      <div class="table-value-div-row">
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('s')">
                              <p class="table-value-text">&check;</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('m')">
                              <p class="table-value-text">&check;</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('l')">
                              <p class="table-value-text">&check;</p>
                          </div>
                      </div>
                  </div>

                  <div class="table-pack">
                      <div class="table-label-div-row">
                          <p [innerHtml]="'coverage-info-dental-cover-label' | transloco" class="table-label-text bold"></p>
                          <span (click)="showAlert(this.dentalCoverInfoTitle, this.dentalCoverInfoMessage)"
                              class="infobutton">&#9432;</span>

                      </div>
                      <div class="table-value-div-row">
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('s')">
                              <p [innerHtml]="'coverage-info-dental-cover-value-s' | transloco" class="table-value-text"></p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('m')">
                              <p [innerHtml]="'coverage-info-dental-cover-value-m' | transloco" class="table-value-text"></p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('l')">
                              <p [innerHtml]="'coverage-info-dental-cover-value-l' | transloco" class="table-value-text"></p>
                          </div>
                      </div>
                  </div>

                  <div class="table-pack">
                      <div class="table-label-div-row">
                          <p [innerHtml]="'coverage-info-emergency-care-label' | transloco" class="table-label-text bold"></p>
                          <span (click)="showAlert(this.emergencyCareInfoTitle, this.emergencyCareInfoMessage)"
                              class="infobutton">&#9432;</span>

                      </div>
                      <div class="table-value-div-row">
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('s')">
                              <p class="table-value-text">CHF 1'000</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('m')">
                              <p class="table-value-text">CHF 1'500</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('l')">
                              <p class="table-value-text">CHF 2'000</p>
                          </div>
                      </div>
                  </div>

                  <div class="table-pack">
                      <div class="table-label-div-row">
                          <p [innerHtml]="'coverage-info-behavioural-treatment-label' | transloco" class="table-label-text bold"></p>
                          <span (click)="showAlert(this.behaviouralTreatmentInfoTitle, this.behaviouralTreatmentInfoMessage)"
                              class="infobutton">&#9432;</span>

                      </div>
                      <div class="table-value-div-row">
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('s')">
                              <p class="table-value-text">&check;</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('m')">
                              <p class="table-value-text">&check;</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('l')">
                              <p class="table-value-text">&check;</p>
                          </div>
                      </div>
                  </div>

                  <div class="table-pack">
                      <div class="table-label-div-row">
                          <p [innerHtml]="'coverage-info-alternative-treatment-label' | transloco" class="table-label-text bold"></p>
                          <span (click)="showAlert(this.alternativeTreatmentInfoTitle, this.alternativeTreatmentInfoMessage)"
                              class="infobutton">&#9432;</span>

                      </div>
                      <div class="table-value-div-row">
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('s')">
                              <p class="table-value-text">CHF 1'000</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('m')">
                              <p class="table-value-text">CHF 1'500</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('l')">
                              <p class="table-value-text">CHF 2'500</p>
                          </div>
                      </div>
                  </div>

                  <div class="table-pack">
                      <div class="table-label-div-row">
                          <p [innerHtml]="'coverage-info-preventive-treatment-label' | transloco" class="table-label-text bold"></p>
                          <span (click)="showAlert(this.preventiveTreatmentInfoTitle, this.preventiveTreatmentInfoMessage)"
                              class="infobutton">&#9432;</span>

                      </div>
                      <div class="table-value-div-row">
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('s')">
                              <p class="table-value-text">&cross;</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('m')">
                              <p class="table-value-text">CHF 150</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('l')">
                              <p class="table-value-text">CHF 200</p>
                          </div>
                      </div>
                  </div>


                  <div class="table-pack">
                      <div class="table-label-div-row">
                          <p [innerHtml]="'coverage-info-medicinal-food-label' | transloco" class="table-label-text bold"></p>
                          <span (click)="showAlert(this.medicinalFoodInfoTitle, this.medicinalFoodInfoMessage)"
                              class="infobutton">&#9432;</span>

                      </div>
                      <div class="table-value-div-row">
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('s')">
                              <p class="table-value-text">&cross;</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('m')">
                              <p class="table-value-text">25% (max. CHF 250)</p>
                          </div>
                        <div class="table-value-col" [class.coverage-level-recommended-col]="isPackageRecommended('l')">
                              <p class="table-value-text">33% (max. CHF 350)</p>
                          </div>
                      </div>
                  </div>


                  <div class="table-pack">
                      <div class="table-label-div-row">
                          <p [innerHtml]="'coverage-info-advanced-treatment-label' | transloco" class="table-label-text bold"></p>
                          <span (click)="showAlert(this.advancedTreatmentInfoTitle, this.advancedTreatmentInfoMessage)"
                              class="infobutton">&#9432;</span>

                      </div>
                  </div>


                  <div class="table-footer">
                      <div class="table-footer-element" [class.coverage-level-recommended-footer]="isPackageRecommended('s')">
                        <div class="table-value-col">
                          <p class="table-value-text">&cross;</p>
                        </div>
                      </div>
                      <div class="table-footer-element" [class.coverage-level-recommended-footer]="isPackageRecommended('m')">
                        <div class="table-value-col">
                          <p class="table-value-text">&cross;</p>
                        </div>
                      </div>
                      <div class="table-footer-element" [class.coverage-level-recommended-footer]="isPackageRecommended('l')">
                        <div class="table-value-col">
                          <p class="table-value-text">&check;</p>
                        </div>
                      </div>
                  </div>
              </div>
            </div>


            <div class="page-header-div">
                <h3>
                    {{ 'coverage-info-addtl-coverage-header' | transloco }}
                </h3>
            </div>

            <div class="bg-container" *ngIf="showPreexistingConditionsInfo">
              <h4 [innerHtml]="'coverage-info-preexisting-conditions-rejected-message' | transloco" *ngIf="getPreexistingConditionsRejected()" class="table-header-subtitle"></h4>

              <div class="coverage-options-wrapper">
                  <div class="coverage-option-tall">

                    <div class="coverage-option-tall-row">
                        <div class="coverage-option-icon-heade-div">
                            <div class="coverage-option-icon-div">
                                <div class="coverage-option-icon dna" *ngIf="!isSelectedById('preexisting-conditions') || getPreexistingConditionsRejected()"></div>
                                <div class="coverage-option-icon check" *ngIf="isSelectedById('preexisting-conditions') && !getPreexistingConditionsRejected()"></div>
                            </div>

                            <h3 class="table-header-title">
                                {{ 'coverage-info-addtl-coverage-preexisting-conditions-header' | transloco }} <span
                                (click)="showAlert(addtlCoveragePreexistingConditionsInfoTitle, addtlCoveragePreexistingConditionsInfoMessage)"
                                class="infobutton">&#9432;</span>
                            </h3>
                        </div>



                        <div>
                            <p *ngIf="this.getCurrentTotal() !== undefined && this.getCurrentCoverageLevelSelected() && this.getPreexistingConditionsTotal()" [innerHtml]="'coverage-info-addtl-amount-text' | transloco : {displayPremiumMonthlygetCurrentTotalnumberfr : displayPremiumMonthly(getPreexistingConditionsTotal()) | number:'1.2-2'}"></p>
                            <p *ngIf="this.getCurrentTotal() === undefined && (!this.getCurrentCoverageLevelSelected() || !this.getPreexistingConditionsTotal()) && isSelectedById('preexisting-conditions')"
                                [innerHtml]="'coverage-info-addtl-amount-placeholder' | transloco : {displayPremiumMonthlygetCurrentTotalnumberfr : displayPremiumMonthly(getPreexistingConditionsTotal()) | number:'1.2-2'}"></p>

                        </div>

                    </div>



                    <div class="coverage-option-tall-row">
                        <div class="coverage-option-text">

                            <p>{{ 'coverage-info-addtl-coverage-preexisting-conditions-short-text' | transloco }}</p>


                        </div>
                        <label id="preexisting-conditions-label" for="preexisting-conditions" (click)="selectPreexistingConditions($event)">
                            <!--
                                                        <input id="preexisting-conditions" class="form-control" type="checkbox"
                                   (click)="track('preexisting-conditions', 'checkbox', isSelectedById('preexisting-conditions')+'')"
                                   formControlName="preexistingConditions">
                            -->
                            <input id="preexisting-conditions" class="form-control" type="checkbox"
                                   (click)="track('preexisting-conditions', 'checkbox', isSelectedById('preexisting-conditions')+'')"
                                   formControlName="preexistingConditions">
                            <div class="choice-div-short-addtl-coverage" [class.addtl-coverage-selected]="isSelectedById('preexisting-conditions')">
                                <p *ngIf="!isSelectedById('preexisting-conditions') || getPreexistingConditionsRejected()" [innerHtml]="'coverage-info-addtl-coverage-preexisting-conditions-select' | transloco" class="center-center-p bold"></p>
                                <p *ngIf="isSelectedById('preexisting-conditions') && !getPreexistingConditionsRejected()" [innerHtml]="'coverage-info-addtl-coverage-preexisting-conditions-select-selected' | transloco" class="center-center-p bold"></p>
                            </div>
                        </label>


                    </div>
                  </div>

                <!--
                    Commented as this is currently not in use
                <label for="liability">
                    <input id="liability" class="form-control" type="checkbox"
                        (click)="track('liability', 'checkbox', isSelectedById('liability')+'')"
                        formControlName="liability">
                    <div class="coverage-option-tall">
                        <div class="coverage-option-icon-div">
                            <img *ngIf="!isSelectedById('liability')" src="assets/x.svg">
                            <img *ngIf="isSelectedById('liability')" src="assets/check.svg">
                        </div>
                    </div>
                </label>
                -->

              </div>
            </div>

            <div class="bg-container">
                <div class="coverage-options-wrapper">

                  <div class="coverage-option-tall">

                            <div class="coverage-option-tall-row">
                                <div class="coverage-option-icon-heade-div">
                                    <div class="coverage-option-icon-div">
                                        <div class="coverage-option-icon global" *ngIf="!isSelectedById('global-coverage')"></div>
                                        <div class="coverage-option-icon check" *ngIf="isSelectedById('global-coverage')"></div>
                                    </div>

                                  <h3 class="table-header-title">
                                        {{ 'coverage-info-addtl-coverage-global-coverage-header' | transloco }} <span
                                        (click)="showAlert(addtlCoverageGlobalCoverageInfoTitle, addtlCoverageGlobalCoverageInfoMessage)"
                                        class="infobutton">&#9432;</span>
                                    </h3>
                                </div>


                                <div>
                                    <p *ngIf="this.getCurrentTotal() !== undefined && this.getCurrentCoverageLevelSelected() && this.getGlobalCoverageTotal()" [innerHtml]="'coverage-info-addtl-amount-text' | transloco : {displayPremiumMonthlygetCurrentTotalnumberfr : displayPremiumMonthly(getGlobalCoverageTotal()) | number:'1.2-2'}"></p>
                                    <p *ngIf="this.getCurrentTotal() === undefined && (!this.getCurrentCoverageLevelSelected() || !this.getGlobalCoverageTotal()) && isSelectedById('global-coverage')"
                                        [innerHtml]="'coverage-info-addtl-amount-placeholder' | transloco : {displayPremiumMonthlygetCurrentTotalnumberfr : displayPremiumMonthly(getPreexistingConditionsTotal()) | number:'1.2-2'}"></p>
                                </div>

                            </div>

                            <div class="coverage-option-tall-row">
                                <div class="coverage-option-text">

                                    <p>{{ 'coverage-info-addtl-coverage-global-coverage-short-text' | transloco }}</p>

                                </div>
                                <label id="global-coverage-label" for="global-coverage">
                                    <input id="global-coverage" class="form-control" type="checkbox"
                                       (click)="track('global-coverage', 'checkbox', isSelectedById('global-coverage')+'')"
                                       formControlName="globalCoverage">
                                    <div class="choice-div-short-addtl-coverage" [class.addtl-coverage-selected]="isSelectedById('global-coverage')">

                                        <p *ngIf="!isSelectedById('global-coverage')" [innerHtml]="'coverage-info-addtl-coverage-global-coverage-select' | transloco" class="center-center-p bold"></p>
                                        <p *ngIf="isSelectedById('global-coverage')" [innerHtml]="'coverage-info-addtl-coverage-global-coverage-select-selected' | transloco" class="center-center-p bold"></p>

                                    </div>
                                </label>
                            </div>

                  </div>
                  <!--
                      Commented as this is currently not in use
                  <label for="liability">
                      <input id="liability" class="form-control" type="checkbox"
                          (click)="track('liability', 'checkbox', isSelectedById('liability')+'')"
                          formControlName="liability">
                      <div class="coverage-option-tall">
                          <div class="coverage-option-icon-div">
                              <img *ngIf="!isSelectedById('liability')" src="assets/x.svg">
                              <img *ngIf="isSelectedById('liability')" src="assets/check.svg">
                          </div>
                      </div>
                  </label>
                  -->

                </div>
              </div>

            
              <div class="bg-container" id="telemed-container">
  
                <div class="coverage-options-wrapper">
                    <div class="coverage-option-tall">
  
                      <div class="coverage-option-tall-row">
                          <div class="coverage-option-icon-heade-div">
                              <div class="coverage-option-icon-div">
                                  <div class="coverage-option-icon check"></div>
                              </div>
  
                              <h3 class="table-header-title">
                                  {{ 'coverage-info-addtl-coverage-telemed-header' | transloco }} <span
                                  (click)="showAlert(addtlCoverageTelemedInfoTitle, addtlCoverageTelemedInfoMessage)"
                                  class="infobutton">&#9432;</span>
                              </h3>
                          </div>
  
  
  
                          <div>
                              <p>{{ 'coverage-info-addtl-coverage-telemed-free' | transloco }}</p>
                          </div>
  
                      </div>
  
  
  
                      <div class="coverage-option-tall-row">
                          <div class="coverage-option-text">
  
                              <p>{{ 'coverage-info-addtl-coverage-telemed-short-text' | transloco }}</p>
  
  
                          </div>
                          <label>
                            <!--
                              <input id="preexisting-conditions" class="form-control" type="checkbox"
                                     formControlName="preexistingConditions">
                            -->
                              <div class="choice-div-short-addtl-coverage" [class.addtl-coverage-selected]="true">
                                  <p [innerHtml]="'coverage-info-addtl-coverage-telemed-included' | transloco" class="center-center-p bold"></p>
                              </div>
                          </label>
  
  
                      </div>
                    </div>
                </div>
              </div>

              <div class="page-header-div">
                <h3>
                    {{ 'coverage-info-copayment-excess-header' | transloco }}
                </h3>
              </div>


          <div id="copayment-excess-wrapper" class="copayment-excess-wrapper bg-container">
                <div class="copayment-excess-element">
                  <p class="table-header-title bold">
                      {{'coverage-info-excess-header' | transloco}} <span (click)="showAlert(excessInfoTitle, excessInfoMessage)" class="infobutton">&#9432;</span>
                  </p>

                  <div class="selectable-list-div">
                    <div class="selectable-list-wrapper"
                         *ngFor="let item of getAppropriateExcessValueOptions()"
                         [class.selected]="item.id===coverageInfoForm.value.excess"

                         (click)="selectExcess(item.id)"
                    >
                      <div class="selectable-recommended" [class.recommended]="isExcessRecommended(item)">
                          <div class="selectable-list-item">{{ item.name }}</div>
                          <span class="selectable-recommended-text">{{ isExcessRecommended(item) ? ('coverage-info-recommended' | transloco) : '' }}</span>
                      </div>
                      <!--<div class="selectable-recommended">
                        <img src="/assets/paw-line.svg" alt=""/>
                        <span>Recommended</span>
                      </div>-->
                    </div>
                  </div>
                </div>
                <!--<div class="copayment-excess-element">
                    <label class="table-header-title" for="copayment">
                      {{'coverage-info-copayment-header' | transloco}} <span (click)="showAlert(copaymentInfoTitle, copaymentInfoMessage)" class="infobutton">&#9432;</span>
                    </label>
                    <ng-select (ngModelChange)="selectedCopaymentValue=$event" [searchable]="false"
                        class="custom-coverage-info form-control" [class.submitted-incomplete]="submittedIncomplete"
                        [clearable]="false" labelForId="copayment" id="copayment" formControlName="copayment"
                        (blur)="track('copayment', 'dropdown', selectedCopaymentValue)">
                        <ng-option *ngFor="let option of getCopaymentValueOptions()" [value]="option.id">
                            {{option.name}}
                        </ng-option>
                    </ng-select>
                </div>-->

                <div class="copayment-excess-element">
                  <p class="table-header-title bold">
                    {{'coverage-info-copayment-header' | transloco}} <span (click)="showAlert(copaymentInfoTitle, copaymentInfoMessage)" class="infobutton">&#9432;</span>
                  </p>

                  <div class="selectable-list-div">
                    <div class="selectable-list-wrapper"
                         *ngFor="let item of getCopaymentValueOptions()"
                         [class.selected]="item.id===coverageInfoForm.value.copayment"

                         (click)="selectCopayment(item.id)"
                    >
                      <div class="selectable-recommended" [class.recommended]="isCopaymentRecommended(item)">
                          <div class="selectable-list-item">{{ item.name }}</div>
                          <span class="selectable-recommended-text">{{ isCopaymentRecommended(item) ? ('coverage-info-recommended' | transloco) : '' }}</span>
                      </div>
                      <!--<div class="selectable-recommended">
                        <img src="/assets/paw-line.svg" alt=""/>
                        <span>Recommended</span>
                      </div>-->
                    </div>
                  </div>
                </div>

                <div class="copayment-explanation">
                  <div class="explanation-container top-part">
                    <div class="exp-how-it-works">
                      <span>{{'claims-calculation-how-it-works' | transloco}}</span>
                    </div>

                    <div class="exp-texts">
                      <p class="bold">{{getExplanationText1()}}</p>
                      <p>{{getExplanationText2()}}</p>
                    </div>

                    <div class="exp-calculations">
                      <div class="calc">
                        <div class="calc-text">
                          <span class="title">{{'claims-calculation-total' | transloco}}</span>
                        </div>
                        <span class="calc-value">{{getTotalPriceExample()}}</span>
                      </div>

                      <div class="calc">
                        <div class="calc-text">
                          <span class="title">{{'claims-calculation-minus-excess' | transloco}}</span>
                          <span class="hint">{{'claims-calculation-minus-excess-hint' | transloco}}</span>
                        </div>
                        <span class="calc-value">- {{getExcessSelectedValue()}}</span>
                      </div>

                      <div class="calc">
                        <div class="calc-text">
                          <span class="title">{{'claims-calculation-you-pay' | transloco}} ({{getCopaymentSelectedValue()}})</span>
                        </div>
                        <span class="calc-value">- {{getCopaymentPriceExample()}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="explanation-container bottom-part">
                    <div class="cut-out left"></div>
                    <div class="cut-out right"></div>
                    <div class="bottom-bordered">
                      <span class="text">{{'claims-calculation-calingo-pays' | transloco}}</span>
                      <span class="value">{{getCalingoPriceExample()}}</span>
                    </div>
                  </div>
                </div>
            </div>

        </form>

    </div>


    <footer id="coverage-info-bottom" class="coverage-bottom">
        <div class="coverage-bottom-content">
            <div class="total-amount-div-with-button">
                <h4 *ngIf="this.getCurrentTotal() !== undefined && this.getCurrentCoverageLevelSelected()" [innerHtml]="('coverage-info-total-amount-text' | transloco : {displayPremiumMonthlygetCurrentTotalnumberfr : displayPremiumMonthly(getCurrentTotal()) | number:'1.2-2'}) + ' ' + (this.pricePercentageReduction !== undefined ? ('percentage-pricing-reduction-text' | transloco : {pricePercentageReduction : this.pricePercentageReduction}) : '') "></h4>
                <h4 *ngIf="this.getCurrentTotal() === undefined || !this.getCurrentCoverageLevelSelected()" [innerHtml]="'coverage-info-total-amount-missing-text' | transloco"></h4>
                <p [innerHtml]="'coverage-info-gic-footer-text' | transloco : {getGICPath : getGICPath(), getPISPath: getPISPath() }" class="oneline-explanation"></p>
                <div *ngIf="sendOfferCC" class="infodiv-broker-offer-cc">
                    <div [innerHtml]="'coverage-info-broker-cc-disclaimer' | transloco" class="infodiv-content"></div>
                </div>
            </div>
            <div class="button-wrapper-in-footer">
                <div class="two-buttons-wrapper-dynamic-direction">
                    <div *ngIf="this.isBrokerFlow && !this.hideSendOfferButton()" class="send-offer-link-button-wrapper">
                        <button [innerHtml]="'coverage-info-broker-submit-button-text' | transloco" class="secondary-button-inverted" type="submit" (click)="onSubmit(true)" form="coverage-info-form"></button>
                        <p [innerHtml]="'coverage-info-broker-cc-send-offer-mail' | transloco" class="send-offer-in-cc-oneline-inverted"></p>
                        <label class="switch">
                            <input type="checkbox" [(ngModel)]="sendOfferCC">
                            <span class="slider round slider-color-inverted"></span>
                        </label>
                    </div>
                    <div>
                        <button [innerHtml]="'coverage-info-submit-button-text' | transloco" type="submit" (click)="onSubmit(false)" form="coverage-info-form"></button>
                    </div>
                </div>
                <p [innerHtml]="'coverage-info-submit-button-error-text' | transloco" *ngIf="submittedIncomplete" class="oneline-explanation red-text"></p>
            </div>
        </div>

    </footer>
</div>
