import {Component, OnInit} from '@angular/core';
import {filter} from "rxjs";
import {StateService} from "../state.service";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
  phoneNumber = '+41 43 588 39 20';

  constructor(private stateService: StateService) {
  }

  ngOnInit(): void {
    this.stateService.getState().pipe(filter(state => !!state.customCssCompanyCode)).subscribe(state => {
      if (state.customCssCompanyCode) {
        this.phoneNumber = environment.companies.find(company => company.code === state.customCssCompanyCode)?.data.phoneNumber || this.phoneNumber;
      }
    });
  }

  setElemHidden(id: string, hidden: boolean): void {
    let elem = document.getElementById(id)
    if (elem !== null && elem !== undefined) {
      elem.hidden = hidden
    }
  }

  toggleElemHidden(id: string): void {
    let elem = document.getElementById(id)
    if (elem !== null && elem !== undefined) {
      elem.hidden = !elem.hidden
    }
  }
}
