<!--<div class="stepper-wrapper">
  <div class="stepper-element-container">
    <div [innerHtml]="'stepper-step-1' | transloco" class="stepper-element hover-responsive active-responsive" (click)="step1()"
         [ngClass]="{ 'active-responsive-text-active': step === 1}"></div>
  </div>

  <div class="stepper-element-container">
    <div [innerHtml]="'stepper-step-2' | transloco" class="stepper-element" (click)="step2()"
         [ngClass]="{'responsive-text-disabled': !step1Complete(), 'hover-responsive': step1Complete(), 'active-responsive': step1Complete(), 'active-responsive-text-active': step === 2 && step1Complete() }"></div>
  </div>

  <div class="stepper-element-container">
    <div [innerHtml]="'stepper-step-3' | transloco" class="stepper-element" (click)="step3()"
         [ngClass]="{'responsive-text-disabled': !(step1Complete() && step2Complete()), 'hover-responsive': step1Complete() && step2Complete(), 'active-responsive': step1Complete() && step2Complete(), 'active-responsive-text-active': step === 3 && step1Complete() && step2Complete() }"></div>
  </div>

  <div class="stepper-element-container">
    <div [innerHtml]="'stepper-step-4' | transloco" class="stepper-element" (click)="step4()"
         [ngClass]="{'responsive-text-disabled': !(step1Complete() && step2Complete() && step3Complete()), 'hover-responsive': step1Complete() && step2Complete() && step3Complete(), 'active-responsive': step1Complete() && step2Complete() && step3Complete(), 'active-responsive-text-active': step === 4 && step1Complete() && step2Complete() && step3Complete() }"></div>
  </div>

</div>-->

<div class="stepper-wrapper stepper-container">
  <div class="stepper">
    <div class="progress-container" #progressContainer>
      <div class="line"></div>
      <div class="line progress-line" #progressLine></div>
      <div class="progress-icon" #progressIcon></div>
    </div>

    <div class="step first-step active" (click)="step1()">
      <div class="dot"></div>
      <div class="text">{{'stepper-step-1'|transloco}}</div>
    </div>

    <div class="step second-step" (click)="step2()">
      <div class="dot"></div>
      <div class="text">{{'stepper-step-2'|transloco}}</div>
    </div>

    <div class="step third-step" (click)="step3()">
      <div class="dot"></div>
      <div class="text">{{'stepper-step-3'|transloco}}</div>
    </div>

    <div class="step third-step" (click)="step4()">
      <div class="dot"></div>
      <div class="text">{{'stepper-step-4'|transloco}}</div>
    </div>
  </div>
</div>
