import {ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';


export function ZipLiechtensteinValidator(control: AbstractControl): ValidationErrors | null {

  const liechtensteinZips = ["9485", "9486", "9487", "9488", "9489", "9490", "9491", "9492", "9493", "9494", "9495", "9496", "9497", "9498"]
  if (liechtensteinZips.includes(control.value?.trim())) {
    return {'zipcode from liechtenstein not allowed': {value: control.value}};

  }




  return null
}

/*
9485 Nendeln
9486 Schaanwald
9487 Gamprin-Bendern
9488 Schellenberg
9489 Schaan
9490 Vaduz
9491 Ruggell
9492 Eschen
9493 Mauren
9494 Schaan
9495 Triesen
9496 Balzers
9497 Triesenberg
9498 Planken
*/