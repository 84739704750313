import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StateService } from '../state.service';

@Component({
  selector: 'app-heroes',
  templateUrl: './heroes.component.html',
  styleUrls: ['./heroes.component.scss']
})
export class HeroesComponent implements OnInit {

  constructor(
    private stateService: StateService,
  ) { }

  step: number = -1
  petName: string | undefined | null

  ngOnInit(): void {
    this.stateService.getState().subscribe(
      value => {
        this.petName = value.petName
      }
    )
  }

  petNameValid(): boolean {
    return this.petName != undefined && this.petName != null && this.petName != ''
  }
}
