import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { StateService } from './state.service';
import { LoadingScreenService } from './loading-screen.service';
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../environments/environment";
import { first } from "rxjs";
import { SimpleModalService } from 'ngx-simple-modal';
import { AcceptAlertComponent } from './accept-alert/accept-alert.component';
import { TranslocoService } from "@ngneat/transloco";
import { TranslocoHttpLoader } from "./transloco-loader";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private style?: HTMLLinkElement;

  constructor(
    private stateService: StateService,
    private loadingScreenService: LoadingScreenService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private route: ActivatedRoute,
    private simpleModalService: SimpleModalService,
    private translate: TranslocoService,
    private translocoHttpLoader: TranslocoHttpLoader,
  ) {

  }

  onDeactivate() {
    // document.body.scrollTop = 0;
    // Alternatively, you can scroll to top by using this other call:
    window.scrollTo(0, 0)
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('custom-css')) {
        const companyCode = params['custom-css'];
        if (!companyCode) {
          console.warn('could not find company code in query params')
          return;
        }
        const company = environment.companies.find(company => company.code === companyCode);
        if (!company) {
          console.warn('could not find company from company code')
          return;
        }
        this.applyWhitelabelling(company);

      } else if ((window as any).contextFrontendConfiguration?.whitelabelstyle) {
        console.warn('found the window object')
        const companyCode = (window as any).contextFrontendConfiguration?.whitelabelstyle
        if (!companyCode) {
          console.warn('could not find company code in query params')
          return;
        }
        const company = environment.companies.find(company => company.code === companyCode);
        if (!company) {
          console.warn('could not find company from company code')
          return;
        }
        this.applyWhitelabelling(company);

      } else {
        this.preloadTranslations()
        this.stateService.getState().pipe(first()).subscribe(state => {
          state.flavor = 'calingo'
          this.stateService.setState(state); // TODO: This tries to save the state in the backend, but it fails if there is only the company code. We should perhaps just save this locally.
        });
      }
    });

    this.loadingScreenService.showLoadingScreenPreSale('dog', true)
    this.stateService.getIsInitialized().then(() => {
      this.loadingScreenService.hideLoadingScreen().then(() => {
        if (this.stateService.getSnapshot().brokerDataSharingRequested === true) {
          this.simpleModalService.addModal(AcceptAlertComponent, {
            partnerName: this.stateService.getSnapshot().brokerName,
            flavor: this.stateService.getSnapshot().flavor
          }, {
            closeOnClickOutside: false,
            closeOnEscape: false
          }).subscribe((result) => {
            this.stateService.getState().pipe(first()).subscribe(state => {
              state.acceptedDataSharing = result;
              this.stateService.setState(state); // TODO: This tries to save the state in the backend, but it fails if there is only the acceptance result. We should perhaps just save this locally.
            });
          });
        }
      })
    })
  }

  private applyWhitelabelling(company: any) {
    console.warn('found company, loading style from', company.cssPath);
    this.loadStyle(company.cssPath, company.isRemote);

    if (company.translationPath) {
      this.loadTranslations(company.translationPath);
    }

    this.stateService.getState().pipe(first()).subscribe(state => {
      state.customCssCompanyCode = company.code;
      state.flavor = company.name;
      this.stateService.setState(state); // TODO: This tries to save the state in the backend, but it fails if there is only the company code. We should perhaps just save this locally.
      let uiController = company.data.uiController;
      if (uiController) {
        this.stateService.setWhitelabellingUIController(uiController);
      }
    });

    this.hideHubspotChat();
  }

  loadTranslations(translationLocation: string) {
    TranslocoHttpLoader.setTranslationPath(translationLocation)
    this.translate.getAvailableLangs().forEach(lang => {
      this.translocoHttpLoader.getWhiteLabelTranslation(lang.toString(), translationLocation).pipe().subscribe((translation) => {
        this.translate.setTranslation(translation, lang.toString(), { merge: false });
        this.translate.load(lang.toString()).subscribe()
      });
    });
  }

  loadStyle(styleUrl: string, isRemote: boolean = false) {
    const cssPath = isRemote ? styleUrl : `assets/css/${styleUrl}`;

    this.style = this.renderer2.createElement('link') as HTMLLinkElement;

    this.renderer2.setProperty(this.style, 'rel', 'stylesheet');
    this.renderer2.setProperty(this.style, 'href', cssPath);
    console.warn('style loader: css path is', cssPath)

    this.renderer2.appendChild(this.document.head, this.style);
  }

  private hideHubspotChat() {
    const hubspotChat = document.querySelector('#hubspot-messages-iframe-container');
    if (hubspotChat) {
      hubspotChat.remove();
    }
  }

  private preloadTranslations() {
    this.translate.getAvailableLangs().forEach(lang => {
      this.translate.load(lang.toString()).subscribe()
    });
  }
}
