<div class="language-choice-element-collapsed">
  <div (click)="toggleExpand()" class="language-choice-element">
    {{ this.currentLanguage }}
    <div class="language-choice-arrow" [class.expanded]="expanded"></div>
  </div>
</div>

<div *ngIf="expanded" class="language-choice-wrapper">
  <div *ngIf="this.currentLanguage.toLowerCase() !== 'de'" class="language-choice-element hover-responsive active-responsive"
       (click)="setLanguage('de'); collapse()">
    DE
  </div>
  <div *ngIf="this.currentLanguage.toLowerCase() !== 'fr'" class="language-choice-element hover-responsive active-responsive"
       (click)="setLanguage('fr'); collapse()">
    FR
  </div>
  <div *ngIf="this.currentLanguage.toLowerCase() !== 'it'" class="language-choice-element hover-responsive active-responsive"
       (click)="setLanguage('it'); collapse()">
    IT
  </div>
  <div *ngIf="this.currentLanguage.toLowerCase() !== 'en'" class="language-choice-element hover-responsive active-responsive"
       (click)="setLanguage('en'); collapse()">
    EN
  </div>
</div>
