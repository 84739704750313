import {Injectable, OnInit} from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { Observable, Subscription } from 'rxjs';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService{

  loadingScreenTimeoutSignaler: Promise<any> = Promise.resolve()
  currentModal: Subscription = new Subscription()

  uspMonthlyCancellation: string = 'loading-screen-usp-monthly-cancellation';
  uspConstantPremium: string = 'loading-screen-usp-constant-premium';
  uspNoAgeLimit: string = 'loading-screen-usp-no-age-limit';
  uspFastClaimService: string = 'loading-screen-usp-fast-claim-service';
  uspPreexistingConditionsInsureable: string = 'loading-screen-usp-preexisting-conditions-insureable';
  uspDevelopedWithVets: string = 'loading-screen-usp-developed-with-vets';

  uspAdaptableAnytime: string = 'loading-screen-usp-adaptable-anytime';
  uspAlwayReachable: string = 'loading-screen-usp-always-reachable';
  uspFollowUs: string = 'loading-screen-usp-follow-us';

  commentItems = [{text: 'loading-screen-comment-1', author: 'loading-screen-comment-author-1'}, {text: 'loading-screen-comment-2', author: 'loading-screen-comment-author-2'}, {text: 'loading-screen-comment-3', author: 'loading-screen-comment-author-3'}, {text: 'loading-screen-comment-4', author: 'loading-screen-comment-author-4'}, {text: 'loading-screen-comment-5', author: 'loading-screen-comment-author-5'}, {text: 'loading-screen-comment-6', author: 'loading-screen-comment-author-6'}]
  preSaleItems = [this.uspMonthlyCancellation, this.uspConstantPremium, this.uspFastClaimService/*, this.uspPreexistingConditionsInsureable*/, this.uspDevelopedWithVets/*, this.uspNoAgeLimit*/]
  postSaleItems = [/*this.uspAdaptableAnytime,*/ this.uspAlwayReachable, this.uspFollowUs]

  minDurationMilisBetweenScreens: number = 7000
  minDurationMilisInitial: number = 4000

  constructor(
    private simpleModalService: SimpleModalService,
    private translation: TranslocoService
  ) { }

  getLoadinScreenTimeoutSignaler(): Promise<any> {
    return this.loadingScreenTimeoutSignaler
  }

  setLoadinScreenTimeoutSignaler(minDurationMilis: number): void {
    this.loadingScreenTimeoutSignaler = new Promise(resolve => setTimeout(resolve, minDurationMilis))
  }

  showLoadingScreenPreSale(species: string, isInitialLoadingScreen?: boolean) {
    if (typeof(isInitialLoadingScreen) !== 'undefined' && isInitialLoadingScreen) {
      this.setLoadinScreenTimeoutSignaler(this.minDurationMilisInitial)
    } else {
      this.setLoadinScreenTimeoutSignaler(this.minDurationMilisBetweenScreens)
    }
    this.currentModal = this.simpleModalService.addModal(LoadingScreenComponent, {comment:this.commentItems[Math.floor(Math.random() * this.commentItems.length)],imageClass: species}, { closeOnClickOutside: false, closeOnEscape: false }).subscribe();
  }

  showLoadingScreenPostSale(species: string, isInitialLoadingScreen?: boolean) {
    if (typeof(isInitialLoadingScreen) !== 'undefined' && isInitialLoadingScreen) {
      this.setLoadinScreenTimeoutSignaler(this.minDurationMilisInitial)
    } else {
      this.setLoadinScreenTimeoutSignaler(this.minDurationMilisBetweenScreens)
    }
    this.currentModal = this.simpleModalService.addModal(LoadingScreenComponent, {comment:this.commentItems[Math.floor(Math.random() * this.commentItems.length)], imageClass: species}, { closeOnClickOutside: false, closeOnEscape: false }).subscribe();
  }

  hideLoadingScreen(): Promise<{}> {
    return this.getLoadinScreenTimeoutSignaler().then(() => {
      // return this.simpleModalService.removeModal()
      // return this.simpleModalService.removeAll()
      this.currentModal.unsubscribe()
      return Promise.resolve({})
    })
  }

  pickRandomElements(arr: string[], n: number): string[] {
    // see https://stackoverflow.com/a/46545530
    let shuffled = arr
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)

    return shuffled.slice(0,n)
  }
}
