// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://ufr23d1n62.execute-api.eu-central-1.amazonaws.com/dev',
  successPageUrl: 'https://pet-calingo.webflow.io/thank-you',
  gtmId: 'GTM-PWBCCC2',

  /* Not in use for now, we simply fetch this from window.location */
  urlPrefixDE: 'https://pet-testing.calingo.ch/de/#/pet-info',
  urlPrefixFR: 'https://pet-testing.calingo.ch/fr/#/pet-info',
  urlPrefixIT: 'https://pet-testing.calingo.ch/it/#/pet-info',
  urlPrefixEN: 'https://pet-testing.calingo.ch/en-US/#/pet-info',

  // REMEMBER: Also put these settings in the evironment.prod.ts file!!
  companies: [
    {
      code: '06408a9c-e5aa-4a71-931b-85427342f407', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'Dev', // not used for now, but it can be useful to have a human readable name
      cssPath: 'dev-custom.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      translationPath: '/assets/dev-custom/i18n/',
      data: {
        phoneNumber: '+48 22 307 00 00',
        successPageUrl: '', // fallback for our own "default" success page
      }
    },
    {
      code: '953a00f8-921b-486d-8bbb-76f73e3a49d3', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'SWICA', // not used for now, but it can be useful to have a human readable name
      cssPath: 'swica.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 80 90 80',
        successPageUrl: '', // fallback for our own "default" success page

        //Product sheet page links
        documentPISPageUrls: {
          de: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet/swica/latest/de/product_information.pdf',
          fr: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet/swica/latest/fr/product_information.pdf',
          en: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet/swica/latest/en/product_information.pdf',
          it: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet/swica/latest/it/product_information.pdf',
        },

      }
    },
    {
      code: 'spg', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'SIMPEGO', // not used for now, but it can be useful to have a human readable name
      cssPath: 'https://pet-testing.calingo.ch/assets/css/simpego.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: true,
      translationPath: 'https://pet-testing.calingo.ch/assets/i18n/',
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page links
        documentGICPageUrls: {
          de: 'https://int.simpego.ch/dam/AVB_Simpego_pet_de.pdf',
          fr: 'https://int.simpego.ch/dam/CGA_Simpego_pet_fr.pdf',
          en: 'https://int.simpego.ch/dam/GCI_Simpego_pet_en.pdf',
          it: 'https://int.simpego.ch/dam/CGA_Simpego_pet_it.pdf',
        },

        //Privacy policy page links
        documentPPPageUrls: {
          de: 'https://www.simpego.ch/de/datenschutz',
          fr: 'https://www.simpego.ch/fr/protection-des-donnees',
          en: 'https://www.simpego.ch/en/privacy',
          it: 'https://www.simpego.ch/it/protezione-dei-dati',
        },

        //Product sheet page links
        documentPISPageUrls: {
          de: 'https://int.simpego.ch/dam/Produktinformation_Simpego_pet_de.pdf',
          fr: 'https://int.simpego.ch/dam/Information_produit_Simpego_pet_fr.pdf',
          en: 'https://int.simpego.ch/dam/Product_Information_Simpego_pet_en.pdf',
          it: 'https://int.simpego.ch/dam/Informazioni_sul_prodotto_Simpego_pet_it.pdf',
        },

        //Information duty page links
        /*
        documentIDPageUrls: {
          de: '',
          fr: '',
          en: '',
          it: '',
        },
        */

        successPagesPurchaseB2C: {
          de: 'https://www.simpego.ch/de/versicherungen/haustiere/haustierversicherung/antragsstrecke/vielen-dank',
          fr: 'https://www.simpego.ch/fr/assurances/animaux/assurance-animaux/formulaire-de-demande/merci',
          en: 'https://www.simpego.ch/en/insurances/pet/pet-insurance/quote/many-thanks',
          it: 'https://www.simpego.ch/it/assicurazioni/animali-domestici/assicurazione-animali-domestici/calcolatore-premi/grazie',
        },

        successPagesPurchaseB2B2C: {
          de: 'https://www.simpego.ch/de/versicherungen/haustiere/haustierversicherung/antragsstrecke/vielen-dank',
          fr: 'https://www.simpego.ch/fr/assurances/animaux/assurance-animaux/formulaire-de-demande/merci',
          en: 'https://www.simpego.ch/en/insurances/pet/pet-insurance/quote/many-thanks',
          it: 'https://www.simpego.ch/it/assicurazioni/animali-domestici/assicurazione-animali-domestici/calcolatore-premi/grazie',
        },

        successPagesOfferB2C: {
          de: 'https://www.simpego.ch/de/versicherungen/haustiere/haustierversicherung/antragsstrecke/offerte',
          fr: 'https://www.simpego.ch/fr/assurances/animaux/assurance-animaux/formulaire-de-demande/offre',
          en: 'https://www.simpego.ch/en/insurances/pet/pet-insurance/quote/personalised',
          it: 'https://www.simpego.ch/it/assicurazioni/animali-domestici/assicurazione-animali-domestici/calcolatore-premi/offerta',
        },

        successPagesOfferB2B2C: {
          de: 'https://www.simpego.ch/de/versicherungen/haustiere/haustierversicherung/antragsstrecke/offerte',
          fr: 'https://www.simpego.ch/fr/assurances/animaux/assurance-animaux/formulaire-de-demande/offre',
          en: 'https://www.simpego.ch/en/insurances/pet/pet-insurance/quote/personalised',
          it: 'https://www.simpego.ch/it/assicurazioni/animali-domestici/assicurazione-animali-domestici/calcolatore-premi/offerta',
        },

        uiController: {
          showB2COfferButton: true,
          hideReentryLinkOfferButton: true,
          showDispatchControl: true,
          showRiskCarrierFooter: true
        }
      }
    },
    {
      code: 'egk', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'EGK', // not used for now, but it can be useful to have a human readable name
      cssPath: 'egk.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'b2g', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'Broker2go', // not used for now, but it can be useful to have a human readable name
      cssPath: 'b2g.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'tow', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'THEOTHERWALK', // not used for now, but it can be useful to have a human readable name
      cssPath: 'tow.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'glx', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'GALAXUS', // not used for now, but it can be useful to have a human readable name
      cssPath: 'glx.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'san', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'SANITAS', // not used for now, but it can be useful to have a human readable name
      cssPath: 'san.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'meiko', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'MEIKO', // not used for now, but it can be useful to have a human readable name
      cssPath: 'meiko.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'petandco', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'PETANDCO', // not used for now, but it can be useful to have a human readable name
      cssPath: 'petandco.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'petcenter', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'PETCENTER', // not used for now, but it can be useful to have a human readable name
      cssPath: 'petcenter.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'birchmeier', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'BIRCHMEIER', // not used for now, but it can be useful to have a human readable name
      cssPath: 'birchmeier.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'dog-boutique', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'DOG-BOUTIQUE', // not used for now, but it can be useful to have a human readable name
      cssPath: 'dog-boutique.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'reka', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'REKA', // not used for now, but it can be useful to have a human readable name
      cssPath: 'reka.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'ipet', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'IPET', // not used for now, but it can be useful to have a human readable name
      cssPath: 'ipet.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      translationPath: 'https://pet-testing.calingo.ch/assets/i18n/formal/',
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        successPagesPurchaseB2C: {
          de: 'https://ipet.ch/versicherung-confirmation',
          fr: 'https://ipet.ch/fr/assurance-confirmation',
          en: 'https://ipet.ch/versicherung-confirmation',
          it: 'https://ipet.ch/versicherung-confirmation',
        },

        successPagesPurchaseB2B2C: {
          de: 'https://ipet.ch/versicherung-confirmation',
          fr: 'https://ipet.ch/fr/assurance-confirmation',
          en: 'https://ipet.ch/versicherung-confirmation',
          it: 'https://ipet.ch/versicherung-confirmation',
        },

        successPagesOfferB2C: {
          de: 'https://ipet.ch/versicherung-confirmation',
          fr: 'https://ipet.ch/fr/assurance-confirmation',
          en: 'https://ipet.ch/versicherung-confirmation',
          it: 'https://ipet.ch/versicherung-confirmation',
        },

        successPagesOfferB2B2C: {
          de: 'https://ipet.ch/versicherung-confirmation',
          fr: 'https://ipet.ch/fr/assurance-confirmation',
          en: 'https://ipet.ch/versicherung-confirmation',
          it: 'https://ipet.ch/versicherung-confirmation',
        },

        documentPISPageUrls: {
          de: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet/iPet/de/product_information.pdf',
          fr: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet/iPet/fr/product_information.pdf',
          en: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet/iPet/de/product_information.pdf',
          it: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet/iPet/de/product_information.pdf',
        },
      }
    },
    {
      code: 'deinhund', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'DEIN-HUND', // not used for now, but it can be useful to have a human readable name
      cssPath: 'deinhund.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'groupemutuel', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'GROUPE-MUTUEL', // not used for now, but it can be useful to have a human readable name
      cssPath: 'groupemutuel.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'tierbedarf-discount', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'TIERBEDARF-DISCOUNT', // not used for now, but it can be useful to have a human readable name
      cssPath: 'tierbedarf-discount.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
    {
      code: 'slkk', // this is a UUID/random identifier created by calingo, can in principle have any format
      name: 'SLKK', // not used for now, but it can be useful to have a human readable name
      cssPath: 'slkk.css', // This references a CSS inside assets/css (if !remote), else it will just take the remote URL
      isRemote: false,
      data: {
        phoneNumber: '0800 00 00 00',
        successPageUrl: '', // fallback for our own "default" success page

        //General insurance conditions page link
        documentGICPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Privacy policy page link
        documentPPPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',

        //Information duty page link
        documentIDPageUrl: 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet',
      }
    },
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
