<div class="loading-screen-content-custom">

  <div class="loading-screen-header-custom">
    <h2 [innerHtml]="'loading-screen-title-one-moment' | transloco"></h2>
  </div>

  <div class="loading-screen-image-container" @imageAnimation>
    <div class="image"
         [ngClass]="{'dog':imageClass && imageClass === 'dog', 'cat':imageClass && imageClass === 'cat'}"></div>
  </div>

  <div class="loading-screen-body-custom" @imageAnimation [@listAnimation]="items?.length">
    <ng-container *ngIf="items && items.length; else noItems">
      <h3 [innerHtml]="'loading-screen-subtitle-did-you-know' | transloco"></h3>

      <div id="list" *ngFor="let item of items">
        <p>&check; {{ item | transloco }}</p>
      </div>
    </ng-container>

    <ng-template #noItems>
      <ng-container *ngIf="comment">
        <div class="review-container">
          <div class="stars">
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
          </div>

          <div class="review-comment">
            <p>{{ comment.text|transloco }}</p>
          </div>

          <div class="review-author">
            <p>{{ comment.author|transloco }}</p>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>

</div>
