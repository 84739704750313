<div class="flex-wrapper">

    <h2 class="cta" [innerHtml]="'banner-header-1' | transloco"></h2>

    <div class="sub-page-root-pet">
        <form id="pet-info-form" [formGroup]="petInfoForm">
            <h3 [innerHtml]="'pet-info-pet-information-header' | transloco"></h3>
            <h4 [innerHtml]="'pet-info-pet-name-header' | transloco"></h4>
            <div class="text-input-wrapper">
                <input [class.submitted-incomplete]="submittedIncomplete" id="pet-name" class="form-control text-input"
                    type="text" formControlName="petName" (ngModelChange)="petName=$event" (keydown.enter)="blur($event.target)" (blur)="track('pet-name', 'text-input', petName)">
            </div>

            <h4 *ngIf="!petNameDefined()" [innerHtml]="'pet-info-pet-species-header-name-undefined' | transloco"></h4>
            <h4 *ngIf="petNameDefined()" [innerHtml]="'pet-info-pet-species-header-name-defined' | transloco : {petName  : petName }"></h4>
            <div class="choice-wrapper-tall">
                <div>
                    <label for="cat">
                        <input id="cat" class="form-control" value="cat" type="radio" (click)="track('pet-species', 'selector', 'cat')" formControlName="species">
                        <div [class.submitted-incomplete]="submittedIncomplete"
                            [class.sibling-selected]="isSelectedById('dog')" [class.selected]="isSelectedById('cat')" class="choice-div-tall">
                            <div class="choice-icon-div">
                                <div class="choice-icon-div-img cat"></div>
                            </div>
                            <div [innerHtml]="'pet-info-pet-species-cat-value' | transloco" class="choice-div-tall-text"></div>
                        </div>
                    </label>
                </div>
                <div>
                    <label for="dog">
                        <input id="dog" class="form-control" value="dog" type="radio" (click)="track('pet-species', 'selector', 'dog')" formControlName="species">
                        <div [class.submitted-incomplete]="submittedIncomplete"
                            [class.sibling-selected]="isSelectedById('cat')" [class.selected]="isSelectedById('dog')" class="choice-div-tall">
                            <div class="choice-icon-div">
                              <div class="choice-icon-div-img dog"></div>
                            </div>
                            <div [innerHtml]="'pet-info-pet-species-dog-value' | transloco" class="choice-div-tall-text"></div>
                        </div>
                    </label>
                </div>
            </div>

            <h4 *ngIf="!petNameDefined()" [innerHtml]="'pet-info-pet-gender-header-name-undefined' | transloco"></h4>
            <h4 *ngIf="petNameDefined()" [innerHtml]="'pet-info-pet-gender-header-name-defined' | transloco : {petName : petName}"></h4>
            <div class="choice-wrapper-short-2">
                <label for="male">
                    <input id="male" class="form-control" value="m" type="radio" (click)="track('pet-gender', 'selector', 'male')" formControlName="petGender">
                    <div [class.submitted-incomplete]="submittedIncomplete"
                        [class.sibling-selected]="isSelectedById('female')"  [class.selected]="isSelectedById('male')" class="choice-div-short-pet">
                        <p [innerHtml]="'pet-info-pet-gender-male-value' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
                <label for="female">
                    <input id="female" class="form-control" value="f" type="radio" (click)="track('pet-gender', 'selector', 'female')" formControlName="petGender">
                    <div [class.submitted-incomplete]="submittedIncomplete"
                        [class.sibling-selected]="isSelectedById('male')" [class.selected]="isSelectedById('female')" class="choice-div-short-pet">
                        <p [innerHtml]="'pet-info-pet-gender-female-value' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
            </div>

            <h4 *ngIf="!petNameDefined()" [innerHtml]="'pet-info-pet-breed-type-header-name-undefined' | transloco"></h4>
            <h4 *ngIf="petNameDefined()" [innerHtml]="'pet-info-pet-breed-type-header-name-defined' | transloco : {               petName  :                petName }"></h4>
            <div class="choice-wrapper-short-3">
                <label for="pedigree">
                    <input id="pedigree" class="form-control" value="pedigree" type="radio" (click)="track('pet-breed', 'selector', 'pedigree')" formControlName="breed">
                    <div [class.submitted-incomplete]="submittedIncomplete"
                        [class.sibling-selected]="isSelectedById('cross') || isSelectedById('mixed')"  [class.selected]="isSelectedById('pedigree')"
                        class="choice-div-short-pet">
                        <p [innerHtml]="'pet-info-pet-breed-type-value-pedigree' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
                <label for="cross">
                    <input id="cross" class="form-control" value="cross" type="radio" (click)="track('pet-breed', 'selector', 'cross')" formControlName="breed">
                    <div [class.submitted-incomplete]="submittedIncomplete"
                        [class.sibling-selected]="isSelectedById('pedigree') || isSelectedById('mixed')"  [class.selected]="isSelectedById('cross')"
                        class="choice-div-short-pet">
                        <p [innerHtml]="'pet-info-pet-breed-type-value-coss' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
                <label for="mixed">
                    <input id="mixed" class="form-control" value="mixed" type="radio" (click)="track('pet-breed', 'selector', 'mixed')" formControlName="breed">
                    <div [class.submitted-incomplete]="submittedIncomplete"
                        [class.sibling-selected]="isSelectedById('pedigree') || isSelectedById('cross')"  [class.selected]="isSelectedById('mixed')"
                        class="choice-div-short-pet">
                        <p [innerHtml]="'pet-info-pet-breed-type-value-mixed' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
            </div>

            <div [hidden]="!showDogBreedSelector()">
                <div class="submenu">
                    <label [innerHtml]="'pet-info-pet-dog-breed-selector-header' | transloco" for="dog-breeds"></label>
                    <ng-select (ngModelChange)="selectedDogBreed=$event" [items]="dogBreedOptions" bindLabel="name" bindValue="id" [searchFn]="breedFilterFunction" [placeholder]="ngSelectPlaceholder()" class="custom-pet-info form-control"
                        [class.submitted-incomplete]="submittedIncomplete" [clearable]="false" labelForId="dog-breeds"
                        id="dog-breeds" formControlName="dogBreed" (blur)="track('dog-breed', 'dropdown', selectedDogBreed)">
                    </ng-select>
                </div>
            </div>

            <div [hidden]="!showCatBreedSelector()">
                <div class="submenu">
                    <label [innerHtml]="'pet-info-pet-cat-breed-selector-header' | transloco" for="cat-breeds"></label>
                    <ng-select (ngModelChange)="selectedCatBreed=$event" [items]="catBreedOptions" bindLabel="name" bindValue="id" [searchFn]="breedFilterFunction" [placeholder]="ngSelectPlaceholder()" class="custom-pet-info form-control"
                        [class.submitted-incomplete]="submittedIncomplete" [clearable]="false" labelForId="cat-breeds"
                        id="cat-breeds" formControlName="catBreed" (blur)="track('cat-breed', 'dropdown', selectedCatBreed)">
                    </ng-select>
                </div>
            </div>



            <div [hidden]="!showDogHeightOptions()">
                <h4 *ngIf="!petNameDefined()" [innerHtml]="'pet-info-pet-tall-dog-header-name-undefined' | transloco"></h4>
                <h4 *ngIf="petNameDefined()" [innerHtml]="'pet-info-pet-tall-dog-header-name-defined' | transloco : {petName  : petName }"></h4>
                <div class="choice-wrapper-short-2">
                    <label for="tall-dog-yes">
                        <input id="tall-dog-yes" class="form-control" value="true" type="radio" (click)="track('big-dog', 'selector', 'true')"
                            formControlName="tallDog">
                        <div [class.submitted-incomplete]="submittedIncomplete"
                            [class.sibling-selected]="isSelectedById('tall-dog-no')"  [class.selected]="isSelectedById('tall-dog-yes')" class="choice-div-short-pet">
                            <p [innerHtml]="'pet-info-pet-tall-dog-value-yes' | transloco" class="center-center-p bold"></p>
                        </div>
                    </label>
                    <label for="tall-dog-no">
                        <input id="tall-dog-no" class="form-control" value="false" type="radio" (click)="track('big-dog', 'selector', 'false')"
                            formControlName="tallDog">
                        <div [class.submitted-incomplete]="submittedIncomplete"
                            [class.sibling-selected]="isSelectedById('tall-dog-yes')"  [class.selected]="isSelectedById('tall-dog-no')" class="choice-div-short-pet">
                            <p [innerHtml]="'pet-info-pet-tall-dog-value-no' | transloco" class="center-center-p bold"></p>
                        </div>
                    </label>
                </div>
            </div>

            <div class="infodiv">
                <div class="infodiv-header">
                    <h5 [innerHtml]="'pet-info-pet-breed-info-header' | transloco"></h5>
                </div>
                <div class="infodiv-content">
                    <ul>
                        <li [innerHtml]="'pet-info-pet-breed-info-key-pedigree' | transloco"></li>
                        <li [innerHtml]="'pet-info-pet-breed-info-key-cross' | transloco"></li>
                        <li [innerHtml]="'pet-info-pet-breed-info-key-mixed' | transloco"></li>
                    </ul>
                </div>
            </div>


            <div *ngIf="showHousecatSelector()">
                <h4 *ngIf="!petNameDefined()" [innerHtml]="'pet-info-pet-housecat-header-name-undefined' | transloco"></h4>
                <h4 *ngIf="petNameDefined()" [innerHtml]="'pet-info-pet-housecat-header-name-defined' | transloco : {petName  : petName }"></h4>
                <div class="choice-wrapper-short-2">
                    <label for="housecat">
                        <input id="housecat" class="form-control" value="housecat" type="radio" (click)="track('cat-housecat', 'selector', 'housecat')" formControlName="housecat">
                        <div [class.submitted-incomplete]="submittedIncomplete"
                            [class.sibling-selected]="isSelectedById('outdoor-cat')" [class.selected]="isSelectedById('housecat')" class="choice-div-short-pet">
                            <p [innerHtml]="'pet-info-pet-housecat-housecat-value' | transloco" class="center-center-p bold"></p>
                        </div>
                    </label>
                    <label for="outdoor-cat">
                        <input id="outdoor-cat" class="form-control" value="outdoorCat" type="radio" (click)="track('cat-housecat', 'selector', 'outdoorCat')" formControlName="housecat">
                        <div [class.submitted-incomplete]="submittedIncomplete"
                            [class.sibling-selected]="isSelectedById('housecat')" [class.selected]="isSelectedById('outdoor-cat')" class="choice-div-short-pet">
                            <p [innerHtml]="'pet-info-pet-housecat-outdoorcat-value' | transloco" class="center-center-p bold"></p>
                        </div>
                    </label>
                </div>
            </div>






            <h4 *ngIf="!petNameDefined()">{{'pet-info-pet-birthdate-header-name-undefined' | transloco}} <span (click)="showAlert(this.petBirthdateInfoTitle, this.petBirthdateInfoMessage)"
                class="infobutton">&#9432;</span></h4>
            <h4 *ngIf="petNameDefined()">{{ 'pet-info-pet-birthdate-header-name-defined' | transloco : {petName  : petName } }} <span (click)="showAlert(this.petBirthdateInfoTitle, this.petBirthdateInfoMessage)"
                class="infobutton">&#9432;</span></h4>
            <div class="text-input-wrapper">
                <input (ngModelChange)="petBirthdate=$event" [class.submitted-incomplete]="submittedIncomplete" [minDate]="{year: 2000, month: 1, day: 1}" [maxDate]="ngbDateEightWeeksInThePast()" outsideDays="hidden"
                [weekdays]="false" id="birthdate" class="form-control text-input" formControlName="petBirthdate" [placeholder]="'datepicker-placeholder' | transloco"
                (click)="d.toggle()" ngbDatepicker #d="ngbDatepicker" (keydown.enter)="blur($event.target)" (blur)="track('pet-birthdate', 'datepicker', petBirthdate)">
            </div>

            <h4 *ngIf="!petNameDefined()" [innerHtml]="'pet-info-pet-neutered-header-name-undefined' | transloco"></h4>
            <h4 *ngIf="petNameDefined()" [innerHtml]="'pet-info-pet-neutered-header-name-defined' | transloco : {petName  : petName }"></h4>
            <div class="choice-wrapper-short-2">
                <label for="neutered-yes">
                    <input id="neutered-yes" class="form-control" value="true" type="radio" (click)="track('pet-neutered', 'selector', 'true')" formControlName="neutered">
                    <div [class.submitted-incomplete]="submittedIncomplete"
                        [class.sibling-selected]="isSelectedById('neutered-no')" [class.selected]="isSelectedById('neutered-yes')" class="choice-div-short-pet">
                        <p [innerHtml]="'pet-info-pet-neutered-value-yes' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
                <label for="neutered-no">
                    <input id="neutered-no" class="form-control" value="false" type="radio" (click)="track('pet-neutered', 'selector', 'false')" formControlName="neutered">
                    <div [class.submitted-incomplete]="submittedIncomplete"
                        [class.sibling-selected]="isSelectedById('neutered-yes')" [class.selected]="isSelectedById('neutered-no')" class="choice-div-short-pet">
                        <p [innerHtml]="'pet-info-pet-neutered-value-no' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
            </div>

            <h4 *ngIf="!petNameDefined()">{{'pet-info-pet-preexisting-conditions-header-name-undefined' | transloco}} <span (click)="showAlert(this.preexistingConditionsInfoTitle, this.preexistingConditionsInfoMessage)"
            class="infobutton">&#9432;</span></h4>
            <h4 *ngIf="petNameDefined()">{{'pet-info-pet-preexisting-conditions-header-name-defined' | transloco : {petName  : petName } }} <span (click)="showAlert(this.preexistingConditionsInfoTitle, this.preexistingConditionsInfoMessage)"
                class="infobutton">&#9432;</span></h4>
            <div class="choice-wrapper-short-2">
              <label for="preexisting-yes">
                <input id="preexisting-yes" class="form-control" value="true" type="radio" (click)="track('pet-preexisting', 'selector', 'true')" formControlName="showPreexistingConditions">
                <div [class.submitted-incomplete]="submittedIncomplete"
                     [class.sibling-selected]="isSelectedById('preexisting-no')" [class.selected]="isSelectedById('preexisting-yes')" class="choice-div-short-pet">
                  <p [innerHtml]="'pet-info-pet-preexisting-value-yes' | transloco" class="center-center-p bold"></p>
                </div>
              </label>
              <label for="preexisting-no">
                <input id="preexisting-no" class="form-control" value="false" type="radio" (click)="track('pet-preexisting', 'selector', 'false')" formControlName="showPreexistingConditions">
                <div [class.submitted-incomplete]="submittedIncomplete"
                     [class.sibling-selected]="isSelectedById('preexisting-yes')" [class.selected]="isSelectedById('preexisting-no')" class="choice-div-short-pet">
                  <p [innerHtml]="'pet-info-pet-preexisting-value-no' | transloco" class="center-center-p bold"></p>
                </div>
              </label>
            </div>

            <div class="infodiv">
                <div class="infodiv-header">
                  <h5 [innerHtml]="'pet-info-preexisting-info-header' | transloco"></h5>
                </div>
                <div [innerHtml]="'pet-info-preexisting-info-content' | transloco" class="infodiv-content">
                  All conditions that were last treated more than 2 years ago are not considered pre-existing conditions. Minor treatments such as a healed ear infection are not considered pre-existing conditions.
                </div>
              </div>

              <h3 [innerHtml]="'pet-info-parent-information-header' | transloco"></h3>


            <h4 [innerHtml]="'pet-info-parent-firstname-header' | transloco"></h4>
            <div class="text-input-wrapper">
                <input (ngModelChange)="firstName=$event" [class.submitted-incomplete]="submittedIncomplete" id="first-name"
                    class="form-control text-input" type="text" formControlName="firstName" (keydown.enter)="blur($event.target)" (blur)="track('parent-firstname', 'text-input', '*****')">
            </div>

            <h4 [innerHtml]="'pet-info-parent-email-header' | transloco"></h4>
            <div class="text-input-wrapper">
                <input (ngModelChange)="email=$event" [class.submitted-incomplete]="submittedIncomplete" id="email" class="form-control text-input"
                    type="text" formControlName="email" (keydown.enter)="blur($event.target)" (blur)="track('parent-email', 'text-input', '*****')">
            </div>







        </form>
        <div class="button-wrapper-with-text">
            <div class="two-buttons-wrapper">
                <div *ngIf="this.isBrokerFlow && !this.hideSendOfferButton()" class="send-offer-link-button-wrapper">
                    <button class="secondary-button" [innerHtml]="'pet-info-broker-submit-button-text' | transloco" type="submit" (click)="onSubmit(true)" form="pet-info-form"></button>
                    <p [innerHtml]="'pet-info-broker-cc-send-offer-mail' | transloco" class="send-offer-in-cc-oneline"></p>
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="sendOfferCC">
                        <span class="slider round slider-color-normal"></span>
                    </label>
                    <div *ngIf="sendOfferCC" class="infodiv-broker-offer-cc">
                        <div [innerHtml]="'pet-info-broker-cc-disclaimer' | transloco" class="infodiv-content"></div>
                    </div>
                </div>


                <button [innerHtml]="'pet-info-submit-button-text' | transloco" type="submit" (click)="onSubmit(false)" form="pet-info-form"></button>
            </div>
            <p [innerHtml]="'pet-info-submit-button-error-text' | transloco" *ngIf="submittedIncomplete" class="oneline-explanation red-text"></p>
            <p [innerHtml]="'pet-info-submit-button-explanation' | transloco : {getPrivacyPolicyPath : getPrivacyPolicyPath() }" class="oneline-explanation"></p>


        </div>
    </div>

</div>
