import { Component, Inject, OnInit } from '@angular/core';
import { StateService } from '../state.service';
import { State } from '../state';
import dogBreedsList from '../../assets/dog_breeds.json';
import catBreedsList from '../../assets/cat_breeds.json';
import dogBreedsListEN from '../../assets/dog_breeds_en.json';
import catBreedsListEN from '../../assets/cat_breeds_en.json';
import dogBreedsListFR from '../../assets/dog_breeds_fr.json';
import catBreedsListFR from '../../assets/cat_breeds_fr.json';
import dogBreedsListIT from '../../assets/dog_breeds_it.json';
import catBreedsListIT from '../../assets/cat_breeds_it.json';
import { Router } from '@angular/router';
import { HttpService } from '../http-service.service';
import { DocumentsService } from '../documents.service';
import { LoadingScreenService } from '../loading-screen.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SuccessPageService } from '../success-page-service.service';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ngbDateToIso, optionalStartDateValidatorFn, fetchQueryParameterFromWindowLocation, createStandardQueryParamsForUrlPrefix, getUrlWithHashLocation } from '../utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT, formatDate } from '@angular/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from '../alert/alert.component';
import { TranslocoService } from '@ngneat/transloco';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-begin-and-pay',
  templateUrl: './begin-and-pay.component.html',
  styleUrls: ['./begin-and-pay.component.scss']
})
export class BeginAndPayComponent implements OnInit {
  closingWaitingMilis = 5000

  constructor(
    private stateService: StateService,
    private router: Router,
    private httpService: HttpService,
    private documentsService: DocumentsService,
    private loadingScreenService: LoadingScreenService,
    private gtmService: GoogleTagManagerService,
    private successPageService: SuccessPageService,
    private simpleModalService: SimpleModalService,
    private translation: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  paymentFrequencyInfoTitle: ()=> string = () => this.translation.translate('closing-begin-and-pay-infobutton-title-payment-frequency');
  paymentFrequencyInfoMessage: ()=> string = () => this.translation.translate('closing-begin-and-pay-infobutton-message-payment-frequency');
  closingErrorInfoTitle: ()=> string = () => this.translation.translate('closing-begin-and-pay-error-title-closing');
  closingErrorInfoMessage: (status: string, url: string, timestamp: string) => string = (status, url, timestamp) => this.translation.translate('closing-begin-and-pay-error-message-closing', { status, url, timestamp });

  ngOnInit(): void {
    this.stateService.getIsInitialized().then(() => {
      console.warn('begin and pay info component checking if state is ok')
      if (!this.stateService.step1Complete()) {
        this.router.navigate(['/pet-info'], { queryParamsHandling: 'merge' });
        return
      }
      if (!this.stateService.step2Complete()) {
        this.router.navigate(['/coverage-info'], { queryParamsHandling: 'merge' });
        return
      }
      if (!this.stateService.step3Complete()) {
        this.router.navigate(['/parent-info'], { queryParamsHandling: 'merge' });
        return
      }
    })
      .then(() => {
        // as we checked previously that all fields are set, we can do some Rambo casting here
        let initState = this.getState()
        this.petName = initState.petName as string
        this.petGender = initState.petGender as string
        this.species = initState.species as string
        this.breed = initState.breed as string
        this.catBreed = this.fetchCatBreedList().find(element => element.id === initState.catBreed)?.name
        this.dogBreed = this.fetchDogBreedList().find(element => element.id === initState.dogBreed)?.name
        this.tallDog = initState.tallDog
        this.petBirthdate = initState.petBirthdate as string
        this.neutered = initState.neutered as boolean
        this.firstName = initState.firstName as string
        this.lastName = initState.lastName as string
        this.email = initState.email as string
        this.coverageLevel = initState.coverageLevel as string
        this.preexistingConditions = initState.preexistingConditions as boolean
        this.globalCoverage = initState.globalCoverage as boolean
        this.excess = initState.excess as number
        this.copayment = initState.copayment as number
        this.parentGender = initState.parentGender as string
        this.parentBirthdate = initState.parentBirthdate as string
        this.street = initState.street as string
        this.number = initState.number as string
        this.zip = initState.zip as string
        this.city = initState.city as string
        this.phoneNumber = initState.phoneNumber as string

        if (initState.paymentFrequency !== undefined && initState.paymentFrequency !== null && initState.paymentFrequency !== '') {
          this.beginAndPayForm.get('paymentFrequency')?.setValue(initState.paymentFrequency)
        } else {
          this.beginAndPayForm.get('paymentFrequency')?.setValue('m')
        }

        let startDate = initState.startDate
        if (startDate !== undefined) {
          let sd = new Date(startDate)
          if (sd.getTime() - Date.now() > 1000 * 3600 * 24) {
            this.beginAndPayForm.get('startDate')?.setValue(startDate)
            this.beginAndPayForm.get('startTomorrow')?.setValue("false")
          }
        }

        if (this.beginAndPayForm.get('startDate')?.value) {
          this.stateService.fetchPremiumCurrentTotal(this.coverageLevel, {startDateOverride: this.beginAndPayForm.get('startDate')?.value})
        } else {
          this.stateService.fetchPremiumCurrentTotal(this.coverageLevel, {})
        }

        this.beginAndPayForm.get('startTomorrow')?.valueChanges.pipe(
          distinctUntilChanged(),
          throttleTime(500),
        ).subscribe(value => {
          if (value === 'true') {
            this.stateService.fetchPremiumCurrentTotal(this.coverageLevel, {startDateOverride: (ngbDateToIso(this.ngbDateTomorrow()) || undefined)})
          } else if (this.beginAndPayForm.get('startDate')?.value) {
            this.stateService.fetchPremiumCurrentTotal(this.coverageLevel, {startDateOverride: this.beginAndPayForm.get('startDate')?.value})
          }
        })

        this.beginAndPayForm.get('startDate')?.valueChanges.pipe(
          distinctUntilChanged(),
          throttleTime(500),
        ).subscribe(value => {
          if (value) {
            this.stateService.fetchPremiumCurrentTotal(this.coverageLevel, {startDateOverride: value})
          } else {
            this.stateService.fetchPremiumCurrentTotal(this.coverageLevel, {})
          }
        })


        this.beginAndPayForm.get('paymentFrequency')?.valueChanges.pipe(
          distinctUntilChanged(),
          throttleTime(500),
        ).subscribe(value => {
          this.showPremiumLoader = true
          if (value) {
            this.stateService.fetchPremiumCurrentTotal(this.coverageLevel, {paymentFrequencyOverride: value}).then(() => {
              this.showPremiumLoader = false
            })
          } else {
            this.stateService.fetchPremiumCurrentTotal(this.coverageLevel, {}).then(() => {
              this.showPremiumLoader = false
            })
          }
        })

      }).then(() => {
      this.loadingScreenService.hideLoadingScreen()
    })

    this.stateService.isBrokerFlowObs.subscribe(
      value => {
        this.isBrokerFlow = value
      }
    )

    this.stateService.getBrokerObject().subscribe(
      value => {
        this.pricePercentageReduction = value.percentagePriceReduction
      }
    )

    // reload breeds when language changes to show correct breed translation
    this.translation.langChanges$.subscribe(lang => {
      let currentState = this.getState()
      this.catBreed = this.fetchCatBreedList().find(element => element.id === currentState.catBreed)?.name
      this.dogBreed = this.fetchDogBreedList().find(element => element.id === currentState.dogBreed)?.name
    })

  }

    // Dummy initialization, will be set in ngOnInit
    petName: string = '';
    petGender: string = '';
    species: string = '';
    breed: string = '';
    catBreed?: string;
    dogBreed?: string;
    tallDog?: boolean;
    petBirthdate: string = '';
    neutered: boolean = false;
    firstName: string = '';
    lastName: string = '';
    parentBirthdate: string = ''
    email: string = '';
    coverageLevel: string = '';
    preexistingConditions: boolean = false;
    globalCoverage: boolean = false;
    excess: number = 500;
    copayment: number = 20;

    parentGender = ''
    street = ''
    number = ''
    zip = ''
    city = ''
    phoneNumber = ''

    startDate: string = ''

    submittedIncomplete = false
    isBrokerFlow: boolean = false
    showSendOfferButtons: boolean = false

    showPremiumLoader: boolean = false

    pricePercentageReduction: string | undefined


    formatDateISOToEurope(isoDate: string): string {
      let s = formatDate(isoDate, 'dd.MM.yyyy', 'fr');
      return s
    }

    getCurrentTotal(): number | undefined {
      return this.stateService.getCurrentTotal()
    }

    ngbDateTomorrow(): NgbDateStruct {
      let tmpDate = new Date()
      tmpDate.setDate(tmpDate.getDate() + 1)
      let ngbDate = { day: tmpDate.getDate(), month: tmpDate.getMonth() + 1, year: tmpDate.getFullYear()}
      return ngbDate
    }

    ngbDateOneYearInTheFuture(): NgbDateStruct {
      let tmpDate = new Date()
      tmpDate.setFullYear(tmpDate.getFullYear() + 1)
      tmpDate.setDate(tmpDate.getDate() - 1)
      let ngbDate = { day: tmpDate.getDate(), month: tmpDate.getMonth() + 1, year: tmpDate.getFullYear()}
      return ngbDate
    }

    toggleShowSendOfferButtons() {
      this.showSendOfferButtons = !this.showSendOfferButtons
    }

    displayCopaymentAsCoveragePercentage(copayment: number): number {
      if (copayment === 0) {
        return 100
      }
      return 80
    }

    fetchDogBreedList() {
      if (this.stateService.getCurrentLocaleId() === 'en') {
        return dogBreedsListEN
      } else if (this.stateService.getCurrentLocaleId() === 'fr') {
        return dogBreedsListFR
      } else if (this.stateService.getCurrentLocaleId() === 'it') {
        return dogBreedsListIT
      } else {
        return dogBreedsList
      }
    }

    fetchCatBreedList() {
      if (this.stateService.getCurrentLocaleId() === 'en') {
        return catBreedsListEN
      } else if (this.stateService.getCurrentLocaleId() === 'fr') {
        return catBreedsListFR
      } else if (this.stateService.getCurrentLocaleId() === 'it') {
        return catBreedsListIT
      } else {
        return catBreedsList
      }
    }

    showAlert(title: ()=>string, message: ()=>string) {
      this.simpleModalService.addModal(AlertComponent, {title: title(), message: message()}, {
        closeOnClickOutside: true,
        closeOnEscape: true
      });
    }

    getState(): State {
      return this.stateService.getSnapshot()
    }

    showB2COfferButton(): boolean {
      return this.stateService.getWhitelabellingUIControllerSnapshot().showB2COfferButton === true && !this.isBrokerFlow
    }

    showDynamicDispatchControlOfferButton(): boolean {
      return this.stateService.getWhitelabellingUIControllerSnapshot().showDispatchControl === true && this.isBrokerFlow
    }

    showRiskCarrierFooter(): boolean {
      return this.stateService.getWhitelabellingUIControllerSnapshot().showRiskCarrierFooter === true
    }

    beginAndPayForm = new FormGroup({
      paymentFrequency: new FormControl('', [Validators.required]),
      startTomorrow: new FormControl('true', [Validators.required]),
      startDate: new FormControl('', [optionalStartDateValidatorFn]),
    });

    getPrivacyPolicyPath(): string {
      return this.documentsService.getCurrentLanguagePrivacyPolicyPath()
    }

    getGICPath(): string {
      return this.documentsService.getCurrentLanguageGICPath()
    }

    getInformationDutyPath(): string {
      return this.documentsService.getCurrentLanguageInformationDutyPath()
    }

    getPISPath(): string {
      return this.documentsService.getCurrentLanguageProductSheetPath()
    }

    isSelectedById(id: string): boolean {
      let elem = this.document.getElementById(id)
      if (elem && (elem as HTMLInputElement).checked !== undefined ) {
        return (elem as HTMLInputElement).checked
      }
      return false
    }

    startTomorrow(): boolean {
      return this.beginAndPayForm.controls['startTomorrow'].value === 'true'
    }

    isMonthlyPayment(): boolean {
      return this.beginAndPayForm.controls['paymentFrequency'].value === 'm'
    }

    isQuarterlyPayment(): boolean {
      return this.beginAndPayForm.controls['paymentFrequency'].value === 'q'
    }

    isBiYearlyPayment(): boolean {
      return this.beginAndPayForm.controls['paymentFrequency'].value === 'b'
    }

    isYearlyPayment(): boolean {
      return this.beginAndPayForm.controls['paymentFrequency'].value === 'y'
    }

    displayPremiumMonthly(premium: number | undefined): string {
      if (premium === undefined) {
        return '0'
      }
      let monthlyPremium: string =  (Math.round((premium/12) * 20) / 20) + ''
      return monthlyPremium
    }

    displayPremiumQuarterly(premium: number | undefined): string {
      if (premium === undefined) {
        return '0'
      }
      let quarterlyPremium: string =  (Math.round((premium/4) * 20) / 20) + ''
      return quarterlyPremium
    }

    displayPremiumBiYearly(premium: number | undefined): string {
      if (premium === undefined) {
        return '0'
      }
      let biYearlyPremium: string =  (Math.round((premium/2) * 20) / 20) + ''
      return biYearlyPremium
    }

    displayPremiumYearly(premium: number | undefined): string {
      if (premium === undefined) {
        return '0'
      }
      let yearlyPremium: string =  (Math.round(premium * 20) / 20) + ''
      return yearlyPremium
    }

    track(itemText: string, formItem: string, value: string, location?: string): void {
      if (value !== null && value !== undefined && value !== '') {
        // this.segment.track('Personal Info Updated', {path: '/closing', item_text: itemText, form_item: formItem, value: value, location: location || null})

        /*
        this.gaService.gtag('event', 'personal_info_updated', {
          'item_text': itemText,
          'form_item': formItem,
          'value': value,
          'location': location || null
        })
        */
      }
    }

    blur(target: EventTarget | null) {
      if (target !== null) {
        (target as HTMLInputElement).blur()
      }
    }

    // see https://medium.com/javascript-everyday/how-to-scroll-to-first-invalid-control-once-a-form-has-been-submitted-eb47d9fbc6e
    scrollToFirstInvalidControl() {
      const firstInvalidControl = this.document.querySelector(
        "form .ng-invalid"
      );

      if (firstInvalidControl !== null && firstInvalidControl !== undefined && (firstInvalidControl as HTMLElement).focus !== undefined) {
        console.warn("first invalid element: " + firstInvalidControl?.id);
        // small hack: ng-select cant be focused for some reason, just focus on the element above it and be done with it.
        if (firstInvalidControl?.id === 'cat-breeds' || firstInvalidControl?.id === 'dog-breeds') {
          setTimeout(() => (this.document.getElementById('pedigree') as HTMLElement).focus(), 10)
          return
        }
        setTimeout(() => (firstInvalidControl as HTMLElement).focus(), 10)
      }
    }

    onSubmit(buttonSource: string) {
      console.warn('submitting closing')
      console.warn(this.beginAndPayForm.value);
      if (this.startTomorrow()) {
        this.beginAndPayForm.get('startDate')?.setValue(ngbDateToIso(this.ngbDateTomorrow()))
      }

      if (this.beginAndPayForm.invalid) {
        console.warn("form invalid")
        this.submittedIncomplete = true
        this.scrollToFirstInvalidControl()
        // this.segment.track('Parent Info Submission Failed', {invalid_fields: this.findInvalidControls(), path: '/closing'})
        // this.gaService.gtag('event', 'parent_info_submission_failed', {invalid_fields: this.findInvalidControls(), path: '/closing'})
        return
      }
      // TODO: Tracking
      this.submittedIncomplete = false

      if (!this.stateService.validateBrokerEmailPresent()) {
        console.warn('invalid broker input')
        // this.stateService.setLocaState(state)
        return
      }

      this.loadingScreenService.showLoadingScreenPostSale(this.species)



      let state = this.beginAndPayForm.value as State


      console.warn('State in step 4 submit', state)

      if (buttonSource === 'close') {
        this.handleClose(state);
      } else if (buttonSource === 'send-pdf-offer-customer') {
        this.handleSendPdfOfferToCustomer(state);
      } else if (buttonSource === 'send-pdf-offer-broker') {
        this.handleSendPdfOfferToBroker(state)
      } else if (buttonSource === 'send-pdf-offer-dynamic-dispatch-control') {
        this.handleSendPdfOfferDynamicDispatchControl(state)
      }


    }

    private handleClose(state: State) {
      let currentLocale = this.stateService.getCurrentLocaleId()
      let localeSubdomain = ''

      if (currentLocale === 'en-US') {
        localeSubdomain = 'en.'
      } else if (currentLocale === 'fr') {
        localeSubdomain = 'fr.'
      } else if (currentLocale === 'it') {
        localeSubdomain = 'it.'
      }


      this.stateService.setState(state).then(() => {
        let state = this.stateService.getSnapshot()
        let contractId = fetchQueryParameterFromWindowLocation('contractID')
        let contractToken = fetchQueryParameterFromWindowLocation('contractToken')

        if (contractId !== null && contractToken !== null) {

          if (state.mfvBehaviorControl) {
            state.mfvBehaviorControl.contractId = contractId
            state.mfvBehaviorControl.contractToken = contractToken
          } else {
            let mfvBehaviorControl = {
              contractToken: contractToken,
              contractId: contractId
            }
            state.mfvBehaviorControl = mfvBehaviorControl
          }

        }



        return this.httpService.close(state);

      }).then(() => {
          let state = this.stateService.getSnapshot();
          let brokerCode = state.brokerCode;
          let firstName = state.firstName;
          let petName = state.petName;
          let referralFactoryCode = state.referralFactoryCode
          if (brokerCode !== undefined && brokerCode !== null && brokerCode !== '') {
            const gtmTag = {
              event: 'purchase_order_submitted_b2b2c',
              pageName: 'closing',
              email: state.email,
              pet_species: state.species,
              pet_gender: state.petGender,
              pet_breed: state.breed,
              dog_breed: state.dogBreed,
              cat_breed: state.catBreed,
              dog_tall: state.tallDog,
              housecat: state.housecat,
              pet_birthdate: state.petBirthdate,
              pet_neutered: state.neutered,
              coverage_level: state.coverageLevel,
              preexisting_conditions: state.preexistingConditions,
              global_coverage: state.globalCoverage,
              liability: state.liability,
              excess: state.excess,
              copayment: state.copayment,
              price_per_year: this.getCurrentTotal(),
              start_date: state.startDate,
              broker_name: state.brokerName,
              broker_code: state.brokerCode,
              flavor: this.stateService.getSnapshot().flavor,
            };
            this.gtmService.pushTag(gtmTag)
            let successPageURL = this.successPageService.getSuccessPagePurchaseB2B2C()
            if (successPageURL && successPageURL !== '') {
              window.location.href =  successPageURL;
            } else {
              let customCssCode = this.stateService.getSnapshot().customCssCompanyCode
              window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/closingapp/b2b2c?firstName=' + firstName + '&petName=' + petName + '&code=' + referralFactoryCode + '&bc=' + brokerCode + '&custom-css=' + customCssCode);
            }
          } else {
            const gtmTag = {
              event: 'purchase_order_submitted_b2c',
              pageName: 'closing',
              email: state.email,
              pet_species: state.species,
              pet_gender: state.petGender,
              pet_breed: state.breed,
              dog_breed: state.dogBreed,
              cat_breed: state.catBreed,
              dog_tall: state.tallDog,
              housecat: state.housecat,
              pet_birthdate: state.petBirthdate,
              pet_neutered: state.neutered,
              coverage_level: state.coverageLevel,
              preexisting_conditions: state.preexistingConditions,
              global_coverage: state.globalCoverage,
              liability: state.liability,
              excess: state.excess,
              copayment: state.copayment,
              price_per_year: this.getCurrentTotal(),
              start_date: state.startDate,
              broker_name: state.brokerName,
              broker_code: state.brokerCode,
              flavor: this.stateService.getSnapshot().flavor,
            };
            this.gtmService.pushTag(gtmTag)
            let successPageURL = this.successPageService.getSuccessPagePurchaseB2C()
            if (successPageURL && successPageURL !== '') {
              window.location.href =  successPageURL;
            } else {
              let customCssCode = this.stateService.getSnapshot().customCssCompanyCode
              window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/closingapp/b2c?firstName=' + firstName + '&petName=' + petName + '&code=' + referralFactoryCode + '&custom-css=' + customCssCode);
            }
          }
        }, (error) => {
          let timestamp = new Date().toString(); 
          this.loadingScreenService.hideLoadingScreen()
          // Handle the error scenario
          if (error instanceof HttpErrorResponse) {
            // Log specific error details
            this.showAlert(this.closingErrorInfoTitle, () => this.closingErrorInfoMessage(error.status.toString(), error.url || 'NA', timestamp))
          } else {
            this.showAlert(this.closingErrorInfoTitle, () => this.closingErrorInfoMessage('NA', 'NA', timestamp))
          }
        });
    }


    private handleSendPdfOfferToCustomer(state: State) {
      let currentLocale = this.stateService.getCurrentLocaleId()
      let localeSubdomain = ''

      if (currentLocale === 'en-US') {
        localeSubdomain = 'en.'
      } else if (currentLocale === 'fr') {
        localeSubdomain = 'fr.'
      } else if (currentLocale === 'it') {
        localeSubdomain = 'it.'
      }

      this.stateService.setState(state).then(() => {
        let state = this.stateService.getSnapshot()
        let contractId = fetchQueryParameterFromWindowLocation('contractID')
        let contractToken = fetchQueryParameterFromWindowLocation('contractToken')

        if (contractId !== null && contractToken !== null) {

          if (state.mfvBehaviorControl) {
            state.mfvBehaviorControl.contractId = contractId
            state.mfvBehaviorControl.contractToken = contractToken
          } else {
            let mfvBehaviorControl = {
              contractToken: contractToken,
              contractId: contractId
            }
            state.mfvBehaviorControl = mfvBehaviorControl
          }

        }

        let urlSearchParams = createStandardQueryParamsForUrlPrefix()
        urlSearchParams.set('bch', 'true') // explicitly hide broker mode
        let url = getUrlWithHashLocation() + '?' + urlSearchParams.toString()

        if (state.mfvBehaviorControl) {
          state.mfvBehaviorControl.partnerURLPrefix = url
        } else {
          let mfvBehaviorControl = {
            partnerURLPrefix: url
          }
          state.mfvBehaviorControl = mfvBehaviorControl
        }

        return this.httpService.sendPdfOfferToCustomer(state);
      }).then(() => {
          let state = this.stateService.getSnapshot();
          let brokerCode = state.brokerCode;
          let firstName = state.firstName;
          let petName = state.petName;
          let species = state.species;
          if (brokerCode !== undefined && brokerCode !== null && brokerCode !== '') {
            const gtmTag = {
              event: 'pdf_offer_to_customer_order_submitted_b2b2c',
              pageName: 'closing',
              email: state.email,
              pet_species: state.species,
              pet_gender: state.petGender,
              pet_breed: state.breed,
              dog_breed: state.dogBreed,
              cat_breed: state.catBreed,
              dog_tall: state.tallDog,
              housecat: state.housecat,
              pet_birthdate: state.petBirthdate,
              pet_neutered: state.neutered,
              coverage_level: state.coverageLevel,
              preexisting_conditions: state.preexistingConditions,
              global_coverage: state.globalCoverage,
              liability: state.liability,
              excess: state.excess,
              copayment: state.copayment,
              price_per_year: this.getCurrentTotal(),
              start_date: state.startDate,
              broker_name: state.brokerName,
              broker_code: state.brokerCode,
              flavor: this.stateService.getSnapshot().flavor,
            };
            this.gtmService.pushTag(gtmTag)
          } else {
            const gtmTag = {
              event: 'pdf_offer_to_customer_order_submitted_b2c',
              pageName: 'closing',
              email: state.email,
              pet_species: state.species,
              pet_gender: state.petGender,
              pet_breed: state.breed,
              dog_breed: state.dogBreed,
              cat_breed: state.catBreed,
              dog_tall: state.tallDog,
              housecat: state.housecat,
              pet_birthdate: state.petBirthdate,
              pet_neutered: state.neutered,
              coverage_level: state.coverageLevel,
              preexisting_conditions: state.preexistingConditions,
              global_coverage: state.globalCoverage,
              liability: state.liability,
              excess: state.excess,
              copayment: state.copayment,
              price_per_year: this.getCurrentTotal(),
              start_date: state.startDate,
              broker_name: state.brokerName,
              broker_code: state.brokerCode,
              flavor: this.stateService.getSnapshot().flavor,
            };
            this.gtmService.pushTag(gtmTag)
          }
          let successPageURL = this.successPageService.getSuccessPageOfferB2C()
          if (successPageURL && successPageURL !== '') {
            window.location.href =  successPageURL;
          } else {
            let customCssCode = this.stateService.getSnapshot().customCssCompanyCode
            let querystring = brokerCode === null || brokerCode === undefined || brokerCode == '' ? '?firstName=' +  firstName + '&petName=' + petName + '&custom-css=' + customCssCode : '?bc=' + brokerCode + '&firstName=' +  firstName + '&petName=' + petName + '&custom-css=' + customCssCode
            if (species !== undefined && species !== null && species !== '' && species === 'cat') {
              window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/send-offer/cat' + querystring)
            } else {
              window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/send-offer/dog' + querystring)
            }
          }
        }, (error) => {
          let timestamp = new Date().toString(); 
          this.loadingScreenService.hideLoadingScreen()
          // Handle the error scenario
          if (error instanceof HttpErrorResponse) {
            // Log specific error details
            this.showAlert(this.closingErrorInfoTitle, () => this.closingErrorInfoMessage(error.status.toString(), error.url || 'NA', timestamp))
          } else {
            this.showAlert(this.closingErrorInfoTitle, () => this.closingErrorInfoMessage('NA', 'NA', timestamp))
          }
        });
    }

    private handleSendPdfOfferToBroker(state: State) {
      let currentLocale = this.stateService.getCurrentLocaleId()
      let localeSubdomain = ''

      if (currentLocale === 'en-US') {
        localeSubdomain = 'en.'
      } else if (currentLocale === 'fr') {
        localeSubdomain = 'fr.'
      } else if (currentLocale === 'it') {
        localeSubdomain = 'it.'
      }

      this.stateService.setState(state).then(() => {
        let state = this.stateService.getSnapshot()

        let contractId = fetchQueryParameterFromWindowLocation('contractID')
        let contractToken = fetchQueryParameterFromWindowLocation('contractToken')

        if (contractId !== null && contractToken !== null) {

          if (state.mfvBehaviorControl) {
            state.mfvBehaviorControl.contractId = contractId
            state.mfvBehaviorControl.contractToken = contractToken
          } else {
            let mfvBehaviorControl = {
              contractToken: contractToken,
              contractId: contractId
            }
            state.mfvBehaviorControl = mfvBehaviorControl
          }

        }

        let urlSearchParams = createStandardQueryParamsForUrlPrefix()
        urlSearchParams.delete('bch')
        let url = getUrlWithHashLocation() + '?' + urlSearchParams.toString()

        if (state.mfvBehaviorControl) {
          state.mfvBehaviorControl.partnerURLPrefix = url
        } else {
          let mfvBehaviorControl = {
            partnerURLPrefix: url
          }
          state.mfvBehaviorControl = mfvBehaviorControl
        }

        return this.httpService.sendPdfOfferToBroker(state);
      })
        /*.then(() => {
          return new Promise(resolve => setTimeout(resolve, this.closingWaitingMilis));
        })*/
        .then(() => {
          let state = this.stateService.getSnapshot();
          let brokerCode = state.brokerCode;
          let firstName = state.firstName;
          let petName = state.petName;
          let species = state.species
          if (brokerCode !== undefined && brokerCode !== null && brokerCode !== '') {
            const gtmTag = {
              event: 'pdf_offer_to_broker_order_submitted_b2b2c',
              pageName: 'closing',
              email: state.email,
              pet_species: state.species,
              pet_gender: state.petGender,
              pet_breed: state.breed,
              dog_breed: state.dogBreed,
              cat_breed: state.catBreed,
              dog_tall: state.tallDog,
              housecat: state.housecat,
              pet_birthdate: state.petBirthdate,
              pet_neutered: state.neutered,
              coverage_level: state.coverageLevel,
              preexisting_conditions: state.preexistingConditions,
              global_coverage: state.globalCoverage,
              liability: state.liability,
              excess: state.excess,
              copayment: state.copayment,
              price_per_year: this.getCurrentTotal(),
              start_date: state.startDate,
              broker_name: state.brokerName,
              broker_code: state.brokerCode,
              flavor: this.stateService.getSnapshot().flavor,
            };
            this.gtmService.pushTag(gtmTag)
          } else {
            const gtmTag = {
              event: 'pdf_offer_to_broker_order_submitted_b2c',
              pageName: 'closing',
              email: state.email,
              pet_species: state.species,
              pet_gender: state.petGender,
              pet_breed: state.breed,
              dog_breed: state.dogBreed,
              cat_breed: state.catBreed,
              dog_tall: state.tallDog,
              housecat: state.housecat,
              pet_birthdate: state.petBirthdate,
              pet_neutered: state.neutered,
              coverage_level: state.coverageLevel,
              preexisting_conditions: state.preexistingConditions,
              global_coverage: state.globalCoverage,
              liability: state.liability,
              excess: state.excess,
              copayment: state.copayment,
              price_per_year: this.getCurrentTotal(),
              start_date: state.startDate,
              broker_name: state.brokerName,
              broker_code: state.brokerCode,
              flavor: this.stateService.getSnapshot().flavor,
            };
            this.gtmService.pushTag(gtmTag)
          }
          let successPageURL = this.successPageService.getSuccessPageOfferB2B2C()
          if (successPageURL && successPageURL !== '') {
            window.location.href =  successPageURL;
          } else {
            let customCssCode = this.stateService.getSnapshot().customCssCompanyCode
            let querystring = brokerCode === null || brokerCode === undefined || brokerCode == '' ? '?firstName=' +  firstName + '&petName=' + petName + '?custom-css=' + customCssCode : '?bc=' + brokerCode + '&firstName=' +  firstName + '&petName=' + petName + '?custom-css=' + customCssCode
            if (species !== undefined && species !== null && species !== '' && species === 'cat') {
              window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/send-offer/cat' + querystring)
            } else {
              window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/send-offer/dog' + querystring)
            }
          }

        }, (error) => {
          let timestamp = new Date().toString(); 
          this.loadingScreenService.hideLoadingScreen()
          // Handle the error scenario
          if (error instanceof HttpErrorResponse) {
            // Log specific error details
            this.showAlert(this.closingErrorInfoTitle, () => this.closingErrorInfoMessage(error.status.toString(), error.url || 'NA', timestamp))
          } else {
            this.showAlert(this.closingErrorInfoTitle, () => this.closingErrorInfoMessage('NA', 'NA', timestamp))
          }
        });
    }

    private handleSendPdfOfferDynamicDispatchControl(state: State) {
      let currentLocale = this.stateService.getCurrentLocaleId()
      let localeSubdomain = ''

      if (currentLocale === 'en-US') {
        localeSubdomain = 'en.'
      } else if (currentLocale === 'fr') {
        localeSubdomain = 'fr.'
      } else if (currentLocale === 'it') {
        localeSubdomain = 'it.'
      }

      this.stateService.setState(state).then(() => {
        let state = this.stateService.getSnapshot()

        let contractId = fetchQueryParameterFromWindowLocation('contractID')
        let contractToken = fetchQueryParameterFromWindowLocation('contractToken')

        if (contractId !== null && contractToken !== null) {

          if (state.mfvBehaviorControl) {
            state.mfvBehaviorControl.contractId = contractId
            state.mfvBehaviorControl.contractToken = contractToken
          } else {
            let mfvBehaviorControl = {
              contractToken: contractToken,
              contractId: contractId
            }
            state.mfvBehaviorControl = mfvBehaviorControl
          }

        }

        let urlSearchParams = createStandardQueryParamsForUrlPrefix()
        if (state.mfvBehaviorControl?.dispatchControl?.offerEmail !== 'agent') {
          urlSearchParams.set('bch', 'true')
        } else {
          urlSearchParams.delete('bch')
        }
        let url = getUrlWithHashLocation() + '?' + urlSearchParams.toString()

        if (state.mfvBehaviorControl) {
          state.mfvBehaviorControl.partnerURLPrefix = url
        } else {
          let mfvBehaviorControl = {
            partnerURLPrefix: url
          }
          state.mfvBehaviorControl = mfvBehaviorControl
        }

        return this.httpService.sendPdfOfferDynamicDispatchControl(state);
      })
        /*.then(() => {
          return new Promise(resolve => setTimeout(resolve, this.closingWaitingMilis));
        })*/
        .then(() => {
          let state = this.stateService.getSnapshot();
          let brokerCode = state.brokerCode;
          let firstName = state.firstName;
          let petName = state.petName;
          let species = state.species
          if (brokerCode !== undefined && brokerCode !== null && brokerCode !== '') {
            const gtmTag = {
              event: 'pdf_offer_dynamic_order_submitted_b2b2c',
              pageName: 'closing',
              email: state.email,
              pet_species: state.species,
              pet_gender: state.petGender,
              pet_breed: state.breed,
              dog_breed: state.dogBreed,
              cat_breed: state.catBreed,
              dog_tall: state.tallDog,
              housecat: state.housecat,
              pet_birthdate: state.petBirthdate,
              pet_neutered: state.neutered,
              coverage_level: state.coverageLevel,
              preexisting_conditions: state.preexistingConditions,
              global_coverage: state.globalCoverage,
              liability: state.liability,
              excess: state.excess,
              copayment: state.copayment,
              price_per_year: this.getCurrentTotal(),
              start_date: state.startDate,
              broker_name: state.brokerName,
              broker_code: state.brokerCode,
              flavor: this.stateService.getSnapshot().flavor,
            };
            this.gtmService.pushTag(gtmTag)
          } else {
            const gtmTag = {
              event: 'pdf_offer_dynamic_order_submitted_b2c',
              pageName: 'closing',
              email: state.email,
              pet_species: state.species,
              pet_gender: state.petGender,
              pet_breed: state.breed,
              dog_breed: state.dogBreed,
              cat_breed: state.catBreed,
              dog_tall: state.tallDog,
              housecat: state.housecat,
              pet_birthdate: state.petBirthdate,
              pet_neutered: state.neutered,
              coverage_level: state.coverageLevel,
              preexisting_conditions: state.preexistingConditions,
              global_coverage: state.globalCoverage,
              liability: state.liability,
              excess: state.excess,
              copayment: state.copayment,
              price_per_year: this.getCurrentTotal(),
              start_date: state.startDate,
              broker_name: state.brokerName,
              broker_code: state.brokerCode,
              flavor: this.stateService.getSnapshot().flavor,
            };
            this.gtmService.pushTag(gtmTag)
          }
          let successPageURL = this.successPageService.getSuccessPageOfferB2C()
          if (state.mfvBehaviorControl?.dispatchControl?.offerEmail === 'agent') {
            successPageURL = this.successPageService.getSuccessPageOfferB2B2C()
          }
          if (successPageURL && successPageURL !== '') {
            window.location.href = successPageURL;
          } else {
            let customCssCode = this.stateService.getSnapshot().customCssCompanyCode
            let querystring = brokerCode === null || brokerCode === undefined || brokerCode == '' ? '?firstName=' +  firstName + '&petName=' + petName + '?custom-css=' + customCssCode : '?bc=' + brokerCode + '&firstName=' +  firstName + '&petName=' + petName + '?custom-css=' + customCssCode
            if (species !== undefined && species !== null && species !== '' && species === 'cat') {
              window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/send-offer/cat' + querystring)
            } else {
              window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/send-offer/dog' + querystring)
            }
          }
        }, (error) => {
          let timestamp = new Date().toString(); 
          this.loadingScreenService.hideLoadingScreen()
          // Handle the error scenario
          if (error instanceof HttpErrorResponse) {
            // Log specific error details
            this.showAlert(this.closingErrorInfoTitle, () => this.closingErrorInfoMessage(error.status.toString(), error.url || 'NA', timestamp))
          } else {
            this.showAlert(this.closingErrorInfoTitle, () => this.closingErrorInfoMessage('NA', 'NA', timestamp))
          }
        });
    }
}
