import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';


export interface LoadingScreenModel {
  items?: string[];
  imageClass?: string;
  comment?: { text: string; author: string};
}

@Component({
  selector: 'app-loading-screen',
  templateUrl: `./loading-screen.component.html`,
  styleUrls: ['./loading-screen.component.scss'],
  animations: [
    // The image should fade in first and then the USP one-by-one
    trigger('imageAnimation', [
      transition('* => *', [
        style({opacity: 0}),
        animate(500, style({opacity: 1}))
      ])
    ]),

    trigger('listAnimation', [
      transition('* => *', [

        query(':enter', style({ opacity: 0 }), {optional: true}),

        query(':enter', stagger('1000ms', [
          animate('1s 500ms ease-in', keyframes([
            style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
          ]))]), {optional: true})
      ])
    ]),
  ]
})
export class LoadingScreenComponent extends SimpleModalComponent<LoadingScreenModel, null> implements LoadingScreenModel {
  items: string[] | undefined;
  imageClass: string | undefined;
  comment: { text: string; author: string} | undefined;

  constructor() {
    super();
  }

}


