import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { StateService } from '../state.service';
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: [ './stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @ViewChild('progressContainer', { static: true })
  progressContainer!: ElementRef;

  @ViewChild('progressLine', { static: true })
  progressLine!: ElementRef;

  @ViewChild('progressIcon', { static: true })
  progressIcon!: ElementRef;

  step: 1 | 2 | 3 | 4 = 1;

  stepLinks = ["pet-info", "coverage-info", "parent-info", "closing"];

  constructor(
    private router: Router,
    private stateService: StateService,
    private transloco: TranslocoService
  ) { }

  step1Complete(): boolean {
    return this.stateService.step1Complete()
  }
  step2Complete(): boolean {
    return this.stateService.step2Complete()
  }
  step3Complete(): boolean {
    return this.stateService.step3Complete()
  }


  ngOnInit(): void {
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        if (this.progressContainer) {
          setTimeout(() => {
            this.moveProgress();
          }, 10);
        }

        if (value.url.startsWith('/pet-info')) {
          this.step = 1
          return;
        }
        if (value.url.startsWith('/coverage-info')) {
          this.step = 2
          return;
        }
        if (value.url.startsWith('/parent-info')) {
          this.step = 3
          return;
        }
        if (value.url.startsWith('/closing')) {
          this.step = 4
          return;
        }
        this.step = 1 // if we select a route that is not part of the steps, we want to clear the step state
      }
    });

    this.transloco.langChanges$.subscribe(() => {
      setTimeout(() => {
        this.onResize();
      }, 10);
    });

    setTimeout(() => {
      this.onResize();
    }, 1000);
  }

  step1(): void {
    console.warn("step 1")
    this.renderProgress()
    this.router.navigate(['/pet-info'], { queryParamsHandling: 'merge' });
  }

  step2(): void {
    console.warn("step 2")
    if (this.stateService.step1Complete()) {
      this.router.navigate(['/coverage-info'], { queryParamsHandling: 'merge' });
    }
  }

  step3(): void {
    console.warn("step 3")
    if (this.stateService.step1Complete() && this.stateService.step2Complete()) {
      this.router.navigate(['/parent-info'], { queryParamsHandling: 'merge' });
    }
  }

  step4(): void {
    console.warn("step 4")
    if (this.stateService.step1Complete() && this.stateService.step2Complete() && this.stateService.step3Complete()) {
      this.router.navigate(['/closing'], { queryParamsHandling: 'merge' });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.renderProgress();
    this.moveProgress();
  }

  renderProgress() {
    const parent = this.progressContainer.nativeElement.parentElement;
    const dots = parent.querySelectorAll('.dot');
    const firstDot = dots[0];
    const lastDot = dots[dots.length - 1];

    const left = firstDot.offsetLeft + firstDot.offsetWidth / 2
    const width = (lastDot.offsetLeft + lastDot.offsetWidth / 2) - left;
    const top = firstDot.offsetTop + firstDot.offsetHeight / 2 - 1;

    this.progressContainer.nativeElement.style.width = this.toPx(width);
    this.progressContainer.nativeElement.style.left = this.toPx(left);
    this.progressContainer.nativeElement.style.top = this.toPx(top);
  }

  moveProgress() {
    const parent = this.progressContainer.nativeElement.parentElement;
    const dots = parent.querySelectorAll('.dot');
    const firstDot = dots[0];
    const currentDot = dots[this.step - 1];
    const prevDotIndex = new Array(...dots).indexOf(new Array(...dots).find(dot => dot.parentElement.classList.contains('active'))) + 1;
    const animationDelayMultiplier = 1 / Math.abs(prevDotIndex - this.step);

    for (let i = 0; i < dots.length; i++) {
      const transitionDelay = Math.abs(i + 1 - prevDotIndex) * animationDelayMultiplier;

      dots[i].parentElement.classList.remove('done');
      dots[i].parentElement.classList.remove('active');
      dots[i].style.removeProperty('transition-delay');
      dots[i].style.setProperty('transition-delay', `${transitionDelay}s`);
    }

    for (let i = 0; i < this.step - 1; i++) {
      dots[i].parentElement.classList.add('done');
    }

    currentDot.parentElement.classList.add('active');
    const currentDotCenter = currentDot.offsetLeft + currentDot.offsetWidth / 2;
    const firstDotCenter = firstDot.offsetLeft + firstDot.offsetWidth / 2;
    const width = currentDotCenter - firstDotCenter;

    this.progressLine.nativeElement.style.width = this.toPx(width);
    this.progressIcon.nativeElement.style.left = this.toPx(width);
  }

  toPx(value: number): string {
    return `${value}px`
  }
}
