import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import {distinctUntilChanged, mergeWith, throttleTime} from 'rxjs/operators';
import {SimpleModalService} from 'ngx-simple-modal';


import {CoverageOverrideParameters, StateService} from '../state.service';
// import { SegmentService } from 'ngx-segment-analytics';
// import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {HttpService} from '../http-service.service';
import {State} from '../state';
import {AlertComponent} from '../alert/alert.component';
import {Router} from '@angular/router';
import {DocumentsService} from '../documents.service';
import {LoadingScreenService} from '../loading-screen.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {TranslocoService} from "@ngneat/transloco";
import {first, firstValueFrom, Subscription} from 'rxjs';


@Component({
  selector: 'app-coverage-info',
  templateUrl: './coverage-info.component.html',
  styleUrls: ['./coverage-info.component.scss']
})
export class CoverageInfoComponent implements AfterViewInit, OnDestroy {
  @ViewChild('tableHeader') tableHeader: ElementRef | undefined;
  @ViewChild('tableHeaderPlaceholder') tableHeaderPlaceholder: ElementRef | undefined;

  age: number = -1;
  species: string = '';

  stateSubscription: Subscription | undefined
  pricePercentageReduction: string | undefined


  excessValueOptionsCat: Option[] = [{name: "CHF 0", id: 0},
    {name: "CHF 150", id: 150},
    {name: "CHF 300", id: 300},
    {name: "CHF 500", id: 500},
    {name: "CHF 1000", id: 1000}]

  excessValueOptionsDog: Option[] = [{name: "CHF 0", id: 0},
    {name: "CHF 300", id: 300},
    {name: "CHF 500", id: 500},
    {name: "CHF 1000", id: 1000},
    {name: "CHF 2000", id: 2000}]

  // note that the IDs are the opposite of the displayed names. This is due to a change in UI, nut changing the IDs requires an API change which would break reentry links.
  copymentValueOptions: Option[] = [{name: "100%", id: 0},
    {name: "80%", id: 20}]
  selectedExcessValue: string = ''
  selectedCopaymentValue: string = ''
  submittedIncomplete = false
  isBrokerFlow: boolean = false
  sendOfferCC: boolean = false
  coverageInfoForm = new FormGroup({
    coverageLevel: new FormControl('', [Validators.required]),
    preexistingConditions: new FormControl(false),
    globalCoverage: new FormControl(false),
    liability: new FormControl(false),
    excess: new FormControl(null, [Validators.required]),
    copayment: new FormControl(null, [Validators.required]),
  });

  coverageLevelList = {
    's': 3000,
    'm': 6000,
    'l': 12000
  }

  annualVetFeeLimitInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-annual-vet-fee');
  annualVetFeeLimitInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-annual-vet-fee');
  treatmentAccidentsAndIllnessInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-treatment-accidents-and-illness');
  treatmentAccidentsAndIllnessInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-treatment-accidents-and-illness');
  dentalCoverInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-dental-cover');
  dentalCoverInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-dental-cover');
  emergencyCareInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-emergency-care');
  emergencyCareInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-emergency-care');
  preventiveTreatmentInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-preventive-treatment');
  preventiveTreatmentInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-preventive-treatment');
  alternativeTreatmentInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-alternative-treatment');
  alternativeTreatmentInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-alternative-treatment');
  advancedTreatmentInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-advanced-treatment');
  advancedTreatmentInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-advanced-treatment');
  medicinalFoodInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-medicinal-food');
  medicinalFoodInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-medicinal-food');
  behaviouralTreatmentInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-behavioural-treatment');
  behaviouralTreatmentInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-behavioural-treatment');
  addtlCoveragePreexistingConditionsInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-addtl-coverage-preexisting-conditions');
  addtlCoveragePreexistingConditionsInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-addtl-coverage-preexisting-conditions');
  addtlCoverageGlobalCoverageInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-addtl-coverage-global-coverage');
  addtlCoverageGlobalCoverageInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-addtl-coverage-global-coverage');
  addtlCoverageTelemedInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-addtl-coverage-telemed');
  addtlCoverageTelemedInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-addtl-coverage-telemed');
  excessInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-excess');
  excessInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-excess');
  copaymentInfoTitle: ()=> string = () => this.translation.translate('coverage-info-infobutton-title-copayment');
  copaymentInfoMessage: ()=> string = () => this.translation.translate('coverage-info-infobutton-message-copayment');

  showPreexistingConditionsInfo: boolean = true;

  constructor(
    private stateService: StateService,
    @Inject(DOCUMENT) private document: Document,
    // private segment: SegmentService,
    // private gaService: GoogleAnalyticsService,
    private httpService: HttpService,
    private simpleModalService: SimpleModalService,
    private loadingScreenService: LoadingScreenService,
    private documentsService: DocumentsService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private translation: TranslocoService
  ) {
  }

  ngAfterViewInit(): void {

    this.stateService.getIsInitialized().then(() => {
      console.warn('coverage info component checking if state is ok')
      if (!this.stateService.step1Complete()) {
        this.router.navigate(['/pet-info'], {queryParamsHandling: 'merge'});
        return
      }
      // no override params needed, as we have defaults for excess and copayment
      this.stateService.fetchPremiumForCoveragesIfPossibleWithCoverageOverrideParams({}).then(() => {
        this.loadingScreenService.hideLoadingScreen()
      })
    })


    // this.segment.page('Coverage info', {path: '/coverage-info'})
    // this.gaService.pageView('/coverage-info', 'Coverage info')


    this.stateSubscription = this.stateService.getState().subscribe(
      value => {
        console.warn(value)
        console.warn('birthdate: ' + value.petBirthdate + ' ' + Object.prototype.toString.call(value.petBirthdate))
        if (value.petBirthdate) {
          let bd = new Date(value.petBirthdate)
          // Date.now() returns unix time in ms
          let timeDiff = Math.abs(Date.now() - bd.getTime());
          let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
          console.warn('age: ' + age)
          this.age = age
        }
        if (value.species) {
          this.species = value.species
        }
        if (value.coverageLevel !== undefined) {
          this.coverageInfoForm.get('coverageLevel')?.setValue(value.coverageLevel)
        }
        if (value.preexistingConditions !== undefined) {
          this.coverageInfoForm.get('preexistingConditions')?.setValue(value.preexistingConditions)
        }
        if (value.globalCoverage !== undefined) {
          this.coverageInfoForm.get('globalCoverage')?.setValue(value.globalCoverage)
        }
        if (value.liability !== undefined) {
          this.coverageInfoForm.get('liability')?.setValue(value.liability)
        }
        if (value.excess !== undefined && value.excess !== null && this.getAppropriateExcessValueOptions().map(o => o.id).includes(value.excess)) {
          this.coverageInfoForm.get('excess')?.setValue(value.excess)
          this.selectedExcessValue = value.excess + '' ?? ''
        } else {
          if (this.species === 'cat') {
            this.coverageInfoForm.get('excess')?.setValue(300) // default value
            this.selectedExcessValue = "300"
          } else {
            this.coverageInfoForm.get('excess')?.setValue(500) // default value
            this.selectedExcessValue = "500"
          }

        }
        if (value.copayment !== undefined && value.copayment !== null&& this.underNineYears()) {
          this.coverageInfoForm.get('copayment')?.setValue(value.copayment)
          this.selectedCopaymentValue = value.copayment + '' ?? ''
        } else {
          this.coverageInfoForm.get('copayment')?.setValue(20) // default value
          this.selectedCopaymentValue = "20"
        }

        this.showPreexistingConditionsInfo = value.showPreexistingConditions ?? true
      }
    );

    if (this.underNineYears()) {
      console.warn('copayment required')
      this.coverageInfoForm.get('copayment')?.setValidators([Validators.required])
    } else {
      console.warn('copayment not required')
      this.coverageInfoForm.get('copayment')?.clearValidators()
    };

    this.coverageInfoForm.get('copayment')?.updateValueAndValidity({onlySelf: true});

    // form changes which require the entire form to be updated with new premiums
    {(this.coverageInfoForm.get('preexistingConditions') as AbstractControl).valueChanges.pipe(
      distinctUntilChanged(),
      throttleTime(500),
    ).subscribe(
      (formValue: any) => {
        this.updateAllPremiums(formValue);
      }
    )}

    {(this.coverageInfoForm.get('globalCoverage') as AbstractControl).valueChanges.pipe(
      distinctUntilChanged(),
      throttleTime(500),
    ).subscribe(
      (formValue: any) => {
        this.updateAllPremiums(formValue);
      }
    )}

    {(this.coverageInfoForm.get('liability') as AbstractControl).valueChanges.pipe(
      distinctUntilChanged(),
      throttleTime(500),
    ).subscribe(
      (formValue: any) => {
        this.updateAllPremiums(formValue);
      }
    )}

    {(this.coverageInfoForm.get('excess') as AbstractControl).valueChanges.pipe(
      distinctUntilChanged(),
      throttleTime(500),
    ).subscribe(
      (formValue: any) => {
        this.updateAllPremiums(formValue);
      }
    )}

    {(this.coverageInfoForm.get('copayment') as AbstractControl).valueChanges.pipe(
      distinctUntilChanged(),
      throttleTime(500),
    ).subscribe(
      (formValue: any) => {
        this.updateAllPremiums(formValue);
      }
    )}



    // form changes which only require the total to be updated with a new premium
    (this.coverageInfoForm.get('coverageLevel') as AbstractControl).valueChanges.pipe(
      distinctUntilChanged(),
      throttleTime(500),
    ).subscribe(
      (formValue: any) => {
        console.warn('Coverage info form changed', formValue)
        let currentCoverageLevel = this.coverageInfoForm.get('coverageLevel')?.value as string | undefined
        let currentPreexistingConditions = this.coverageInfoForm.get('preexistingConditions')?.value as boolean | undefined
        let currentGlobalCoverage = this.coverageInfoForm.get('globalCoverage')?.value as boolean | undefined
        let currentLiability = this.coverageInfoForm.get('liability')?.value as boolean | undefined
        let currentExcess = this.coverageInfoForm.get('excess')?.value as number | undefined
        let currentCopayment = this.coverageInfoForm.get('copayment')?.value as number | undefined

        let coverageOverrideParameters: CoverageOverrideParameters = {
          preexistingConditionsOverride: currentPreexistingConditions,
          globalCoverageOverride: currentGlobalCoverage,
          liabilityOverride: currentLiability,
          excessOverride: currentExcess,
          copaymnetOverride: currentCopayment,
        }
        // this.stateService.fetchPremiumForCoveragesIfPossibleWithCoverageOverrideParams(coverageOverrideParameters)

        if (currentCoverageLevel !== undefined && currentCoverageLevel !== null && currentCoverageLevel !== '') {
          this.stateService.fetchPremiumCurrentTotal(currentCoverageLevel, coverageOverrideParameters);
        }
      }
    )

    this.stateService.isBrokerFlowObs.subscribe(
      value => {
        this.isBrokerFlow = value
      }
    )

    this.stateService.getBrokerObject().subscribe(
      value => {
        this.pricePercentageReduction = value.percentagePriceReduction
      }
    )

  }

  ngOnDestroy() {
    // Unsubscribe from the subscription to prevent memory leaks
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  getSelectedCoverageLevelPrice() {
    let selected = this.coverageInfoForm.get('coverageLevel')?.value as string | undefined;

    if (selected) {
      // @ts-ignore
      return this.coverageLevelList[selected];
    } else {
      return undefined;
    }
  }

  isExcessRecommended(item: Option) {
    if (this.species === 'dog') {
      return item.id === 500;
    } else if (this.species === 'cat') {
      return item.id === 300;
    } else {
      // fallback
      return false;
    }
  }

  isCopaymentRecommended(item: Option) {
    if (this.species === 'dog') {
      if (this.age <= 3) {
        return false;
      } else {
        return item.id === 20;
      }
    } else if (this.species === 'cat') {
      if (this.age <= 5) {
        return false;
      } else {
        return item.id === 20;
      }
    } else {
      // fallback
      return false;
    }
  }

  isPackageRecommended(packageType: 's' | 'm' | 'l') {
    if (this.species === 'dog') {
      if (this.age <= 3) {
        return packageType === 'm';
      } else {
        return false;
      }
    } else if (this.species === 'cat') {
      if (this.age <= 5) {
        return packageType === 'm';
      } else {
        return false;
      }
    } else {
      // fallback
      return false;
    }
  }

  hideSendOfferButton(): boolean {
    return this.stateService.getWhitelabellingUIControllerSnapshot().hideReentryLinkOfferButton === true
  }

  getCopaymentValueOptions(): Option[] {
    if (this.underNineYears()) {
      return this.copymentValueOptions
    } else {
      return [{name: "80%", id: 20}] // note that the IDs are the opposite of the displayed names. This is due to a change in UI, nut changing the IDs requires an API change which would break reentry links.
    }
  }

  getCurrentTotal(): number | undefined {
    return this.stateService.getCurrentTotal()
  }

  getCurrentCoverageLevelSelected(): string | undefined {
    let currentCoverageLevel = this.coverageInfoForm.get('coverageLevel')?.value as string | undefined
    return currentCoverageLevel
  }

  getPreexistingConditionsRejected(): boolean {
    let pect = this.stateService.getPreexistingConditionsTotal()
    // console.warn('coverage, pect is', pect)
    return pect !== undefined && pect < 0
  }

  getPreexistingConditionsTotal(): number | undefined {
    return this.stateService.getPreexistingConditionsTotal()
  }

  getGlobalCoverageTotal(): number | undefined {
    return this.stateService.getGlobalCoverageTotal()
  }

  getSTotal(): number | undefined {
    return this.stateService.getSTotal()
  }

  getMTotal(): number | undefined {
    return this.stateService.getMTotal()
  }

  getLTotal(): number | undefined {
    return this.stateService.getLTotal()
  }

  getSBase(): number | undefined {
    return this.stateService.getSBase()
  }

  getMBase(): number | undefined {
    return this.stateService.getMBase()
  }

  getLBase(): number | undefined {
    return this.stateService.getLBase()
  }

  updateAllPremiums(formValue: any) {
    console.warn('Coverage info form changed', formValue);
    let currentCoverageLevel = this.coverageInfoForm.get('coverageLevel')?.value as string | undefined;
    let currentPreexistingConditions = this.coverageInfoForm.get('preexistingConditions')?.value as boolean | undefined;
    let currentGlobalCoverage = this.coverageInfoForm.get('globalCoverage')?.value as boolean | undefined;
    let currentLiability = this.coverageInfoForm.get('liability')?.value as boolean | undefined;
    let currentExcess = this.coverageInfoForm.get('excess')?.value as number | undefined;
    let currentCopayment = this.coverageInfoForm.get('copayment')?.value as number | undefined;

    let coverageOverrideParameters: CoverageOverrideParameters = {
      preexistingConditionsOverride: currentPreexistingConditions,
      globalCoverageOverride: currentGlobalCoverage,
      liabilityOverride: currentLiability,
      excessOverride: currentExcess,
      copaymnetOverride: currentCopayment,
    };
    this.stateService.fetchPremiumForCoveragesIfPossibleWithCoverageOverrideParams(coverageOverrideParameters);

    if (currentCoverageLevel !== undefined && currentCoverageLevel !== null && currentCoverageLevel !== '') {
      this.stateService.fetchPremiumCurrentTotal(currentCoverageLevel, coverageOverrideParameters);
    }
  }

  underNineYears(): boolean {
    return this.age < 9;
  }

  getPetName(): string | undefined {
    return this.stateService.getSnapshot().petName
  }

  getAppropriateExcessValueOptions(): Option[] {
    // technically, this.stateService.getSnapshot().species could be undefined, but this is checked onInit, so we assume it is either 'dog' or 'cat' here
    if (this.stateService.getSnapshot().species === 'dog') {
      return this.excessValueOptionsDog.filter(elem => this.underNineYears() || elem.id > 0)
    } else {
      return this.excessValueOptionsCat.filter(elem => this.underNineYears() || elem.id > 0)
    }
  }

  isSelectedById(id: string): boolean {
    let elem = this.document.getElementById(id)
    if (elem && (elem as HTMLInputElement).checked !== undefined) {
      return (elem as HTMLInputElement).checked
    }
    return false
  }

  // see https://medium.com/javascript-everyday/how-to-scroll-to-first-invalid-control-once-a-form-has-been-submitted-eb47d9fbc6e
  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.document.querySelector(
      "form .ng-invalid"
    );

    if (firstInvalidControl !== null && firstInvalidControl !== undefined && (firstInvalidControl as HTMLElement).focus !== undefined) {
      console.warn("first invalid element: " + firstInvalidControl?.id);
      // small hack: ng-select cant be focused for some reason, just focus on the element above it and be done with it.
      if (firstInvalidControl?.id === 'excess' || firstInvalidControl?.id === 'copayment') {
        setTimeout(() => (this.document.getElementById('copayment-excess-wrapper') as HTMLElement).focus(), 10)
        return
      }
      setTimeout(() => (firstInvalidControl as HTMLElement).focus(), 10)
    }
  }

  findInvalidControls(): string[] {
    const invalid = [];
    const controls = this.coverageInfoForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  isSelectedCoverageLevel(level: string): boolean {
    return this.coverageInfoForm.get('coverageLevel')?.value === level
  }

  showAlert(title: ()=>string, message: ()=>string) {
    this.simpleModalService.addModal(AlertComponent, {title: title(), message: message()}, {
      closeOnClickOutside: true,
      closeOnEscape: true
    });
  }

  track(itemText: string, formItem: string, value: string, location?: string): void {
    if (value !== null && value !== undefined && value !== '') {
      // this.segment.track('Coverage Info Updated', {path: '/coverage-info', item_text: itemText, form_item: formItem, value: value, location: location || null})
      /*
      this.gaService.gtag('event', 'coverage_info_updated', {
        'item_text': itemText,
        'form_item': formItem,
        'value': value,
        'location': location || null
      })
      */
    }
  }

  displayPremiumMonthly(premium: number | undefined): string {
    if (premium === undefined) {
      return '0'
    }
    let monthlyPremium: string = (Math.round((premium / 12) * 20) / 20) + ''
    return monthlyPremium
  }

  getGICPath(): string {
    return this.documentsService.getCurrentLanguageGICPath()
  }

  getPISPath(): string {
    return this.documentsService.getCurrentLanguageProductSheetPath()
  }

  onSubmit(isBrokerFlowButton: boolean) {
    // TODO: Use EventEmitter with form value
    if (this.coverageInfoForm.invalid) {
      console.warn('invalid coverage form: ', this.findInvalidControls())
      this.submittedIncomplete = true
      this.scrollToFirstInvalidControl()
      // this.segment.track('Coverage Info Submission Failed', {invalid_fields: this.findInvalidControls(), path: '/coverage-info'})
      // this.gaService.gtag('event', 'coverage_info_submission_failed', {invalid_fields: this.findInvalidControls(), path: '/coverage-info'})
      return
    }

    if (!this.stateService.validateBrokerEmailPresent()) {
      console.warn('invalid broker input')
      return
    }

    this.loadingScreenService.showLoadingScreenPreSale(this.stateService.getSnapshot().breed || 'dog')

    console.warn("submitting coverage")
    console.warn(this.coverageInfoForm.value);
    /*
    this.segment.track('Coverage Info Submitted', {
      path: '/coverage-info',
      coverage_level: this.coverageInfoForm.get('coverageLevel')?.value,
      preexisting_conditions: this.coverageInfoForm.get('preexistingConditions')?.value,
      global_coverage: this.coverageInfoForm.get('globalCoverage')?.value,
      liability: this.coverageInfoForm.get('liability')?.value,
      excess: this.coverageInfoForm.get('excess')?.value,
      copayment: this.coverageInfoForm.get('copayment')?.value,
      price_per_year: this.stateService.getCurrentTotal(),
    })
    */
   /*
    this.gaService.gtag('event', 'coverage_info_submitted', {
      'path': '/coverage-info',
      'coverage_level': this.coverageInfoForm.get('coverageLevel')?.value,
      'preexisting_conditions': this.coverageInfoForm.get('preexistingConditions')?.value,
      'global_coverage': this.coverageInfoForm.get('globalCoverage')?.value,
      'liability': this.coverageInfoForm.get('liability')?.value,
      'excess': this.coverageInfoForm.get('excess')?.value,
      'copayment': this.coverageInfoForm.get('copayment')?.value,
      'price_per_year': this.stateService.getCurrentTotal(),
    })
    */
    const gtmTag = {
      event: 'coverage_info_submitted',
      pageName: 'coverage-info',
      coverage_level: this.coverageInfoForm.get('coverageLevel')?.value,
      preexisting_conditions: this.coverageInfoForm.get('preexistingConditions')?.value,
      global_coverage: this.coverageInfoForm.get('globalCoverage')?.value,
      liability: this.coverageInfoForm.get('liability')?.value,
      excess: this.coverageInfoForm.get('excess')?.value,
      copayment: this.coverageInfoForm.get('copayment')?.value,
      price_per_year: this.stateService.getCurrentTotal(),
      flavor: this.stateService.getSnapshot().flavor,
    };
    this.gtmService.pushTag(gtmTag)
    /*
    this.segment.identify(sha256.SHA256(this.stateService.getSnapshot().email as any).toString(), {
      path: '/coverage-info',
      coverage_level: this.coverageInfoForm.get('coverageLevel')?.value,
      preexisting_conditions: this.coverageInfoForm.get('preexistingConditions')?.value,
      global_coverage: this.coverageInfoForm.get('globalCoverage')?.value,
      liability: this.coverageInfoForm.get('liability')?.value,
      excess: this.coverageInfoForm.get('excess')?.value,
      copayment: this.coverageInfoForm.get('copayment')?.value,
      price_per_year: this.stateService.getCurrentTotal(),
    })
    */

    this.submittedIncomplete = false

    let state = this.coverageInfoForm.value as State
    if (this.getPreexistingConditionsRejected()) {
      state.preexistingConditions = false
    }

    if (isBrokerFlowButton) {
      this.stateService.setState(state).then(
        () => {
          let sessionId = this.stateService.getSnapshot().sessionId
          if (sessionId) {
            return this.httpService.sendOffer(sessionId, this.sendOfferCC)
          }
          return null
        }
      ).then(() => {
        let currentLocale = this.stateService.getCurrentLocaleId()
        let localeSubdomain = ''

        if (currentLocale === 'en-US') {
          localeSubdomain = 'en.'
        } else if (currentLocale === 'fr') {
          localeSubdomain = 'fr.'
        } else if (currentLocale === 'it') {
          localeSubdomain = 'it.'
        }
        let state = this.stateService.getSnapshot()
        let species = state.species
        let bc = state.brokerCode
        let firstName = state.firstName
        let petName = state.petName
        let customCssCode = this.stateService.getSnapshot().customCssCompanyCode
        if (species !== undefined && species !== null && species !== '' && species === 'cat') {
          window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/send-offer/cat' + '?bc=' + bc + '&firstName=' + firstName + '&petName=' + petName + '&custom-css=' + customCssCode)
        } else {
          window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/send-offer/dog' + '?bc=' + bc + '&firstName=' + firstName + '&petName=' + petName + '&custom-css=' + customCssCode)
        }
      })
    } else {
      this.stateService.setState(state).then(() => this.router.navigate(['/parent-info'], {queryParamsHandling: 'merge'})
      )
    }
  }

  selectExcess(item: number) {
    this.coverageInfoForm.get('excess')?.setValue(item)
  }

  selectCopayment(item: number) {
    this.coverageInfoForm.get('copayment')?.setValue(item)
  }

  getCopaymentSelectedValue(): string {
    return this.coverageInfoForm.get('copayment')?.value + '%'
  }

  getExcessSelectedValue(): string {
    if(!this.coverageInfoForm.get('excess') || this.coverageInfoForm.get('excess')?.value === null || this.coverageInfoForm.get('excess')?.value === undefined) {
      return '';
    }
    return 'CHF ' + this.coverageInfoForm.get('excess')?.value.toFixed(0)
  }

  getExplanationText1(): string {
    if(!this.coverageInfoForm.get('excess') || this.coverageInfoForm.get('excess')?.value === null || this.coverageInfoForm.get('excess')?.value === undefined) {
      return '';
    }

    let petName = this.stateService.getSnapshot().petName

    if (this.stateService.getSnapshot().species === 'dog') {
      if (this.coverageInfoForm.get('excess')?.value >= 500) {
        return this.translation.translate('claims-calculation-dog-expensive-1', {petName})
      } else {
        return this.translation.translate('claims-calculation-dog-regular-1', {petName})
      }
    } else {
      if (this.coverageInfoForm.get('excess')?.value >= 500) {
        return this.translation.translate('claims-calculation-cat-expensive-1', {petName})
      } else {
        return this.translation.translate('claims-calculation-cat-regular-1', {petName})
      }
    }
  }

  getExplanationText2(): string {
    if(!this.coverageInfoForm.get('excess') || this.coverageInfoForm.get('excess')?.value === null || this.coverageInfoForm.get('excess')?.value === undefined) {
      return '';
    }

    let petName = this.stateService.getSnapshot().petName

    if (this.stateService.getSnapshot().species === 'dog') {
      if (this.coverageInfoForm.get('excess')?.value >= 500) {
        return this.translation.translate('claims-calculation-dog-expensive-2', {petName})
      } else {
        return this.translation.translate('claims-calculation-dog-regular-2', {petName})
      }
    } else {
      if (this.coverageInfoForm.get('excess')?.value >= 500) {
        return this.translation.translate('claims-calculation-cat-expensive-2', {petName})
      } else {
        return this.translation.translate('claims-calculation-cat-regular-2', {petName})
      }
    }
  }

  getTotalPriceExample() {
    if(!this.coverageInfoForm.get('excess') || this.coverageInfoForm.get('excess')?.value === null || this.coverageInfoForm.get('excess')?.value === undefined) {
      return '';
    }

    if (this.stateService.getSnapshot().species === 'dog') {
      if (this.coverageInfoForm.get('excess')?.value >= 500) {
        return `CHF 2'520`
      } else {
        return `CHF 630`
      }
    } else {
      if (this.coverageInfoForm.get('excess')?.value >= 500) {
        return `CHF 1'470`
      } else {
        return `CHF 490`
      }
    }
  }

  getCopaymentPriceExample() {
    if(!this.coverageInfoForm.get('excess') || this.coverageInfoForm.get('excess')?.value === null || this.coverageInfoForm.get('excess')?.value === undefined || !this.coverageInfoForm.get('copayment') || this.coverageInfoForm.get('copayment')?.value === null || this.coverageInfoForm.get('copayment')?.value === undefined) {
      return '';
    }

    let copaymentValue = this.coverageInfoForm.get('copayment')?.value
    let excess = this.coverageInfoForm.get('excess')?.value

    if (copaymentValue === 0) {
      return `CHF 0`
    }

    if (this.stateService.getSnapshot().species === 'dog') {
      if (excess >= 500) {
        let amount = (2520 - excess) * (copaymentValue/100)
        return `CHF ${amount.toFixed(0)}`
      } else {
        let amount = (630 - excess) * (copaymentValue/100)
        return `CHF ${amount.toFixed(0)}`
      }
    } else {
      if (excess >= 500) {
        let amount = (1470 - excess) * (copaymentValue/100)
        return `CHF ${amount.toFixed(0)}`
      } else {
        let amount = (490 - excess) * (copaymentValue/100)
        return `CHF ${amount.toFixed(0)}`
      }
    }
  }

  getCalingoPriceExample() {
    if(!this.coverageInfoForm.get('excess') || this.coverageInfoForm.get('excess')?.value === null || this.coverageInfoForm.get('excess')?.value === undefined || !this.coverageInfoForm.get('copayment') || this.coverageInfoForm.get('copayment')?.value === null || this.coverageInfoForm.get('copayment')?.value === undefined) {
      return '';
    }

    const excess = this.coverageInfoForm.get('excess')?.value
    const copayment = this.coverageInfoForm.get('copayment')?.value

    if (this.stateService.getSnapshot().species === 'dog') {
      if (excess >= 500) {
        let amount = (2520 - excess) * ((100 - copayment)/100)
        return `CHF ${amount.toFixed(0)}`
      } else {
        let amount = (630 - excess) * ((100 - copayment)/100)
        return `CHF ${amount.toFixed(0)}`
      }
    } else {
      if (excess >= 500) {
        let amount = (1470 - excess) * ((100 - copayment)/100)
        return `CHF ${amount.toFixed(0)}`
      } else {
        let amount = (490 - excess) * ((100 - copayment)/100)
        return `CHF ${amount.toFixed(0)}`
      }
    }
  }

  selectPreexistingConditions(event: Event) {
    let pec = this.coverageInfoForm.get('preexistingConditions')?.value as boolean | undefined;
    if (pec === false) {
      event.preventDefault();
      event.stopPropagation()

      this.simpleModalService.addModal(AlertComponent, {
        title: this.addtlCoveragePreexistingConditionsInfoTitle(),
        message: this.addtlCoveragePreexistingConditionsInfoMessage(),
        cancelButton: true
      }, {
        closeOnClickOutside: true,
        closeOnEscape: true
      }).subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.coverageInfoForm.get('preexistingConditions')?.setValue(true)
        }
      });
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.tableHeader && this.tableHeaderPlaceholder) {

      // when we need to left header at bottom of table minus header height and some offset to see the last row (108px)
      if (window.scrollY > this.tableHeader.nativeElement.parentElement.offsetTop + this.tableHeader.nativeElement.parentElement.clientHeight - this.tableHeader.nativeElement.clientHeight - 108) {
        this.tableHeaderPlaceholder.nativeElement.style.height = this.tableHeader.nativeElement.clientHeight + 'px';
        this.tableHeader.nativeElement.style.width = this.tableHeader.nativeElement.parentElement.clientWidth + 'px';
        this.tableHeader.nativeElement.style.position = 'absolute';
        this.tableHeader.nativeElement.style.top = this.tableHeader.nativeElement.parentElement.offsetTop + this.tableHeader.nativeElement.parentElement.clientHeight - this.tableHeader.nativeElement.clientHeight - 108 + 'px';
        this.tableHeader.nativeElement.classList.add('fixed');
        return;
      }

      // when we need to fix header on top of screen after scrolling to the top of the table
      if (window.scrollY > this.tableHeader.nativeElement.parentElement.offsetTop) {
        this.tableHeaderPlaceholder.nativeElement.style.height = this.tableHeader.nativeElement.clientHeight + 'px';
        this.tableHeaderPlaceholder.nativeElement.style.width = this.tableHeader.nativeElement.clientWidth + 'px';
        this.tableHeader.nativeElement.style.width = this.tableHeader.nativeElement.parentElement.clientWidth + 'px';
        this.tableHeader.nativeElement.style.position = 'fixed';
        this.tableHeader.nativeElement.style.top = '0';
        this.tableHeader.nativeElement.classList.add('fixed');
        return;
      }

      // when we need to unfix header when scrolling up
      if (window.scrollY < this.tableHeader.nativeElement.parentElement.offsetTop) {
        this.tableHeaderPlaceholder.nativeElement.style.height = '0';
        this.tableHeader.nativeElement.style.width = 'auto';
        this.tableHeader.nativeElement.style.position = 'relative';
        this.tableHeader.nativeElement.style.top = '0';
        this.tableHeader.nativeElement.classList.remove('fixed');
        return;
      }
    }
  }
}

interface Option {
  name: string,
  id: number
}
